import type { StartAvatarResponse } from "@heygen/streaming-avatar";
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  VoiceEmotion,
  TaskType,
  TaskMode,
} from "@heygen/streaming-avatar";
import { useMemoizedFn, usePrevious } from "ahooks";
import { CONST } from "lib/const";
import { useIsDesktop } from "lib/device";
import {
  useAvatarLogChat,
  useGetAvatarClick,
  useGetAvatarLogChat,
  useGetSubdomainData,
  useRequestAvatarQuota,
} from "modules/Tenant/homeTenantApi";
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
} from "react";
import { useEffect, useRef, useState } from "react";
import { useAvatarStore } from "./avatarStore";
import { AvatarMobile } from "./AvatarMobile";
import { AvatarDesktop } from "./AvatarDesktop";
import { INTRO } from "./openingIntro";
import { useToastStore } from "components/Toast/toastStore";
import { isBrowser } from "lib/ssr";
import { getSubDomain, isMalukuURL } from "lib/host";
import { PROMPT_6 } from "./quizPrompt";
import { useAuthStore } from "modules/Auth/authStore";
import { trackEventGA, trackEventGTM } from "lib/amplitude";
import { useMediaStore as useMedia } from "modules/Course/mediaStore";
import { useMediaStore } from "@vidstack/react";

export interface ChatType {
  session_id: string;
  start_chat: number | Date | null;
  end_chat: number | Date | null;
  chat: {
    user?: string;
    avatar?: string;
  }[];
}

export interface GetChatType {
  chat: {
    user?: string;
    avatar?: string;
    date: string | Date;
  }[];
}

export interface GetSummaryType {
  summary: {
    title: string;
    content: string;
    date: string | Date;
  }[];
}

export const InteractiveAvatar = forwardRef<
  { handleInterrupt: () => Promise<void> },
  {
    isCourse?: boolean;
    currentUser: any;
    isSmaller?: boolean;
    setIsSmaller?: Dispatch<SetStateAction<boolean>>;
    isAnyFiltered?: boolean;
    isKeywordsSearched?: boolean;
    isMyClass?: boolean;
  }
>(
  (
    {
      isCourse = false,
      currentUser,
      isSmaller,
      setIsSmaller,
      isAnyFiltered = false,
      isKeywordsSearched = false,
      isMyClass = false,
    },
    ref
  ) => {
    const subDomain = isBrowser()
      ? getSubDomain(window.location.hostname)
      : null;
    const showToast = useToastStore((state) => state.showToast);
    const { setStreamAvatar, setDataAvatarGlobal } = useAvatarStore();
    const isDesktop = useIsDesktop();

    const { player, playerVideoJs, isPlayerReady } = useMedia();
    const media = useMediaStore(player);

    const { isScrolled, setIsScrolled } = useAvatarStore();
    const [isLoadingSession, setIsLoadingSession] = useState(false);
    const [isLoadingRepeat, setIsLoadingRepeat] = useState(false);
    const [stream, setStream] = useState<MediaStream>();
    const [debug, setDebug] = useState<string>();
    const [dataAvatar, setDataAvatar] = useState<StartAvatarResponse>();
    const [text, setText] = useState<string>("");
    const [textQuiz, setTextQuiz] = useState<string>(PROMPT_6);
    const mediaStream = useRef<HTMLVideoElement>(null);
    const avatar = useRef<StreamingAvatar | null>(null);
    const { setAvatar } = useAvatarStore();
    const [isVoiceChatActive, setIsVoiceChatActive] = useState(false);
    const [micIsLoading, setMicIsLoading] = useState(false);
    const [modeIsLoading, setModeIsLoading] = useState(false);
    const [disabledMic, setDisabledMic] = useState(false);

    const [chatMode, setChatMode] = useState("voice_mode");
    const [isUserTalking, setIsUserTalking] = useState(false);
    const [textValue, setTextValue] = useState<string>("");

    const [isIntro, setIsIntro] = useState(false);
    const [avatarMessage, setAvatarMessage] = useState<string[]>([]);
    const [avatarEndMessage, setAvatarEndMessage] = useState(false);
    const [userMessage, setUserMessage] = useState<string[]>([]);
    const [userEndMessage, setUserEndMessage] = useState(false);
    const [isSaveChat, setIsSaveChat] = useState(false);
    const [summary, setSummary] = useState<string>("");
    const [saveChat, setSaveChat] = useState<ChatType>({
      session_id: "",
      start_chat: null,
      end_chat: null,
      chat: [],
    });
    const [chat, setChat] = useState<GetChatType>({
      chat: [],
    });
    const [historySummary, setHistorySummary] = useState<GetSummaryType>({
      summary: [],
    });
    const [summaryRecap, setSummaryRecap] = useState<GetSummaryType>({
      summary: [],
    });

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const [isDisconnected, setIsDisconnected] = useState(false);

    const avatarClickApi = useGetAvatarClick();
    const getAvatarLogChat = useGetAvatarLogChat();
    const avatarLogChat = useAvatarLogChat();

    useEffect(() => {
      if (player && isPlayerReady) {
        if (stream) {
          player?.current?.pause();
        } else {
          player?.current?.play();
        }
      }

      if (playerVideoJs?.current) {
        if (stream) {
          playerVideoJs?.current?.pause();
        } else {
          playerVideoJs?.current?.play();
        }
      }

      if (player?.current) {
        player.current.addEventListener("canPlay", () => {
          console.log("Media Player Ready");
        });
      }

      return () => {
        if (player?.current) {
          player.current.removeEventListener("canPlay", () => {
            console.log("Media Player Not Ready");
          });
        }
      };
    }, [player, media, isPlayerReady, playerVideoJs, stream]);

    const openAIApiKey = CONST.OPENAI_APIKEY;

    const fetchAccessToken = async () => {
      const trialApiKey = CONST.HEYGEN_APIKEY_TRIAL;
      const apiKey = CONST.HEYGEN_APIKEY;

      const response = await fetch(
        "https://api.heygen.com/v1/streaming.create_token",
        {
          method: "POST",
          headers: {
            "x-api-key": apiKey,
          },
        }
      );

      const { data } = await response.json();
      return data.token;
    };

    let lastDisplayedDate: string | null = null;

    const handleMinimize = (event?: any) => {
      event?.stopPropagation();

      setIsScrolled(true);
    };

    const handleFullScreen = (event) => {
      event.stopPropagation();

      trackEventGTM({
        event: "gaia_maximize_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
        },
      });

      trackEventGA({
        event: "gaia_maximize_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
        },
      });

      if (window.scrollY === 0) {
        setIsScrolled(false);
      } else {
        const onScroll = () => {
          if (window.scrollY === 0) {
            setIsScrolled(false);

            window.removeEventListener("scroll", onScroll);
          }
        };

        window.addEventListener("scroll", onScroll);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    useEffect(() => {
      if (isMyClass) {
        handleMinimize();
      }
    }, [isMyClass]);

    const [activeTab, setActiveTab] = useState("live");

    useEffect(() => {
      setChat(() => ({
        chat: [],
      }));
      setSummaryRecap(() => ({
        summary: [],
      }));

      getAvatarLogChat.doFetch(
        {
          slug: currentUser ? currentUser?.referral : subDomain,
        },
        {
          onSuccess(data: any) {
            let tempChatSet = new Set();

            data?.data?.forEach((item: any) => {
              let parsedChat = JSON.parse(item?.chat_message);

              parsedChat?.forEach((chat: any) => {
                tempChatSet.add(
                  JSON.stringify({
                    ...chat,
                    date: item.created_at,
                  })
                );
              });

              if (
                item?.summary_title?.trim() &&
                item?.summary_content?.trim()
              ) {
                setSummaryRecap((prev) => ({
                  summary: [
                    ...prev.summary,
                    {
                      title: item?.summary_title,
                      content: item?.summary_content,
                      date: item.created_at,
                    },
                  ],
                }));
              }
            });

            const tempChat = Array.from(tempChatSet).map((chat: any) =>
              JSON.parse(chat)
            );

            setChat(() => ({
              chat: tempChat,
            }));
          },
        }
      );
    }, [stream, currentUser]);

    const [isHiddenAvatar, setIsHiddenAvatar] = useState(false);
    const { remainingDuration, setRemainingDuration } = useAvatarStore();
    const [maxDuration, setMaxDuration] = useState(0);
    const [showLimitDesc, setShowLimitDesc] = useState(false);
    const [isIncreaseLimit, setIsIncreaseLimit] = useState(false);
    const [limitChecked, setLimitChecked] = useState(true);
    const [isRequestSent, setIsRequestSent] = useState(false);

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(
        remainingSeconds
      ).padStart(2, "0")}`;
    };

    useEffect(() => {
      if (stream) {
        if (remainingDuration > 0) {
          const countdown = setInterval(() => {
            setRemainingDuration((prev) => prev - 1);
          }, 1000);

          return () => clearInterval(countdown);
        } else {
          endSession();
        }
      }
    }, [remainingDuration, stream]);

    useEffect(() => {
      avatarClickApi.doFetch(
        {
          slug: currentUser ? currentUser?.referral : subDomain,
        },
        {
          onSuccess(data: any) {
            const duration = data?.max_duration - data?.duration;

            setRemainingDuration(duration <= 0 ? 0 : duration);
            setMaxDuration(data?.max_duration);

            if (duration <= 0) {
              setIsScrolled(true);
            }

            if (currentUser?.tenant?.status === "Trial" && data?.is_hidden) {
              setIsHiddenAvatar(true);

              if (stream) {
                endSession();
              }
            }
          },
        }
      );
    }, [currentUser, stream, avatar.current, isSaveChat]);

    useEffect(() => {
      if (stream) {
        const interval = setInterval(() => {
          avatarClickApi.doFetch({
            slug: currentUser ? currentUser?.referral : subDomain,
          });
        }, 15000);

        return () => clearInterval(interval);
      }
    }, [currentUser, stream, avatar.current]);

    const determineAvatarQuality = () => {
      // Get the estimated downlink speed in Mbps
      const downlinkSpeed = (navigator as any).connection?.downlink || 0;

      let quality;

      // Categorize the internet speed
      if (downlinkSpeed < 1) {
        quality = "Low";
      } else if (downlinkSpeed >= 1 && downlinkSpeed < 3) {
        quality = "Medium";
      } else {
        quality = "High";
      }

      // Map the quality to AvatarQuality
      const _AvatarQuality = {
        Low: AvatarQuality.Low,
        Medium: AvatarQuality.Medium,
        High: AvatarQuality.High,
      };

      return _AvatarQuality[quality];
    };

    async function startSession() {
      setIsScrolled(true);
      setShowSidebar(false);
      setShowSummarySidebar(false);
      setShowQuizSidebar(false);

      trackEventGTM({
        event: "gaia_start_session_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
          quota: remainingDuration,
        },
      });

      trackEventGA({
        event: "gaia_start_session_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
          quota: remainingDuration,
        },
      });

      const currentDate = new Date();
      const endDuration = new Date(currentUser?.tenant?.duration?.end_date);

      if (currentDate > endDuration) {
        setIsLoadingSession(false);

        return;
      }

      avatarClickApi.doFetch(
        {
          slug: currentUser ? currentUser?.referral : subDomain,
        },
        {
          onSuccess(data: any) {
            if (currentUser?.tenant?.status === "Trial" && data?.is_hidden) {
              endSession();
            }
          },
        }
      );

      setIsLoadingSession(true);
      const newToken = await fetchAccessToken();
      const quality = determineAvatarQuality();

      avatar.current = new StreamingAvatar({
        token: newToken,
      });
      avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
        setDisabledMic(false);
        console.log("Avatar started talking", e);
      });
      avatar.current.on(StreamingEvents.AVATAR_TALKING_MESSAGE, (message) => {
        setAvatarMessage((prev) => [...prev, message?.detail?.message]);
        console.log("Avatar talking message:", message?.detail?.message);
      });
      avatar.current.on(StreamingEvents.AVATAR_END_MESSAGE, (message) => {
        setAvatarEndMessage(true);
        console.log("Avatar end message:", message);
      });
      avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
        console.log("Avatar stopped talking", e);
      });
      avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
        setSaveChat((prev) => ({
          ...prev,
          end_chat: Math.floor(new Date().getTime() / 1000),
        }));
        setAvatarMessage([]);
        setUserMessage([]);
        console.log("Stream disconnected");

        setIsDisconnected(true);
        setIsLoadingSession(false);
        endSession();
      });
      avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
        setSaveChat((prev) => ({
          ...prev,
          start_chat: Math.floor(new Date().getTime() / 1000),
        }));
        console.log("Stream ready:", event.detail);
        setIsLoadingSession(false);
        setStream(event.detail);
        setStreamAvatar(event.detail);
        setIsDisconnected(false);
      });
      avatar.current?.on(StreamingEvents.USER_START, (event) => {
        setDisabledMic(true);
        console.log("User started talking:", event);
        setIsUserTalking(true);
      });
      avatar.current.on(StreamingEvents.USER_TALKING_MESSAGE, (message) => {
        setUserMessage((prev) => [...prev, message?.detail?.message]);
        console.log("User talking message:", message);
      });
      avatar.current.on(StreamingEvents.USER_END_MESSAGE, (message) => {
        setUserEndMessage(true);
        console.log("User end message:", message);
      });
      avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
        console.log("User stopped talking:", event);
        setIsUserTalking(false);
      });
      try {
        const res = await avatar.current.createStartAvatar({
          quality: quality,
          avatarName: currentUser?.tenant?.setting?.heygen_avatar,
          voice: {
            voiceId: currentUser?.tenant?.setting?.heygen_voice,
            rate: 1.0,
            emotion: VoiceEmotion.EXCITED,
          },
          knowledgeId: currentUser?.tenant?.setting?.heygen_knowledge,
          language: currentUser?.tenant?.setting?.heygen_language,
          disableIdleTimeout: true,
        });

        setAvatar(avatar.current);

        INTRO.map((item) => {
          if (
            item?.knowledgeId === currentUser?.tenant?.setting?.heygen_knowledge
          ) {
            if (currentUser?.tenant?.setting?.heygen_language === "en") {
              setText(item?.introTextEN);
            } else {
              setText(item?.introTextID);
            }
          }
        });

        setDataAvatar(res);
        setDataAvatarGlobal(res);
        setSaveChat((prev) => ({
          ...prev,
          session_id: res?.session_id,
          start_chat: Math.floor(new Date().getTime() / 1000),
        }));
        await avatar.current?.startVoiceChat({
          useSilencePrompt: false,
        });
        setChatMode("voice_mode");
        setIsVoiceChatActive(true);
      } catch (error) {
        setIsLoadingSession(false);

        console.error("Error starting avatar session:", error);
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        await handleSpeak();
      };

      fetchData();
      setIsIntro(true);
    }, [text]);

    // useEffect(() => {
    //   const fetchData = async () => {
    //     await handleSpeakQuiz();
    //     await handleInterrupt();
    //   };

    //   fetchData();
    // }, [textQuiz, isIntro]);

    const [summaryLoading, setSummaryLoading] = useState(false);

    const API_URL = "https://api.openai.com/v1/chat/completions";

    const summarizeText = (text: string) => {
      const regex = /"([^"]*)"/g;
      let result = [];
      let match;

      while ((match = regex.exec(text)) !== null) {
        result.push(match[1]);
      }

      return result;
    };

    const improveSummary = async (sentences: any) => {
      setSummaryLoading(true);
      setSummary("");

      const promptText = `Buatkan ringkasan pada kalimat tersebut serta judul ringkasannya : "${JSON.stringify(
        sentences
      )}"`;

      const messages = [{ role: "user", content: promptText }];

      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${openAIApiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: messages,
            stream: false,
          }),
        });

        const responseJson = await response.json();

        const result = summarizeText(responseJson.choices[0].message.content);

        if (result.length > 0) {
          return result[0];
        } else {
          return responseJson.choices[0].message.content;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSummaryLoading(false);
      }
    };

    const processAvatarMessage = async (avatarResponse) => {
      try {
        const summaryText = await improveSummary(avatarResponse);

        setSummary(summaryText);
      } catch (error) {
        console.error("Error improving avatar text:", error);
      }
    };

    useEffect(() => {
      if (avatarEndMessage) {
        processAvatarMessage(avatarMessage.join(""));
      }
    }, [avatarEndMessage]);

    function getTextsAfterColon(input) {
      const lines = input.split("\n");
      return lines.map((line) => {
        const colonIndex = line.indexOf(":");
        return colonIndex !== -1 ? line.substring(colonIndex + 1).trim() : null;
      });
    }

    useEffect(() => {
      const isQuizPrompt = userMessage
        .toString()
        .includes("Quiz Engagement (Mandatory)");

      if (isIntro && avatarEndMessage) {
        setSaveChat((prev) => ({
          ...prev,
          chat: [...prev.chat, { user: "-", avatar: avatarMessage.join("") }],
        }));

        setChat((prev) => ({
          chat: [
            ...prev.chat,
            {
              user: "-",
              avatar: avatarMessage.join(""),
              date: new Date().toISOString(),
            },
          ],
        }));

        setAvatarMessage([]);

        setAvatarEndMessage(false);
        setIsIntro(false);
      }

      if (avatarEndMessage && userEndMessage) {
        setSaveChat((prev) => ({
          ...prev,
          chat: [
            ...prev.chat,
            {
              user: !isQuizPrompt ? userMessage.toString() : "-",
              avatar: avatarMessage.join(""),
            },
          ],
        }));

        setChat((prev) => ({
          chat: [
            ...prev.chat,
            {
              user: !isQuizPrompt ? userMessage.toString() : "-",
              avatar: avatarMessage.join(""),
              date: new Date().toISOString(),
            },
          ],
        }));

        const resultSummary = getTextsAfterColon(summary);

        setHistorySummary((prev) => ({
          ...prev,
          summary: [
            ...prev.summary,
            {
              title: resultSummary[1] ?? resultSummary[2] ?? resultSummary,
              content: resultSummary[0] ?? resultSummary,
              date: new Date().toISOString(),
            },
          ],
        }));

        setAvatarMessage([]);
        setUserMessage([]);

        setAvatarEndMessage(false);
        setUserEndMessage(false);
      }
    }, [avatarEndMessage, userEndMessage, isIntro]);

    useEffect(() => {
      const data = {
        slug: currentUser ? currentUser?.referral : subDomain,
        ...saveChat,
      };

      setIsSaveChat(false);

      if (isDisconnected) {
        avatarLogChat.doFetch(data, {
          onSuccess(data: any) {
            if (currentUser?.tenant?.status === "Trial" && data?.is_hidden) {
              setIsHiddenAvatar(true);
            }

            setIsDisconnected(false);
            setIsSaveChat(true);

            setSaveChat((prev) => ({
              ...prev,
              chat: [],
            }));
          },
          onError(err) {
            const error: any = err.getActualType();
            console.error("Error saving avatar log chat:", error);

            setIsDisconnected(false);
          },
        });
      }
    }, [isDisconnected, currentUser]);

    async function handleStartVoiceChat(event) {
      event.stopPropagation();

      trackEventGTM({
        event: "gaia_unmute_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
        },
      });

      trackEventGA({
        event: "gaia_unmute_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
        },
      });

      setMicIsLoading(true);
      if (!avatar.current) {
        setDebug("Avatar API not initialized");
        return;
      }

      await avatar.current?.startVoiceChat();
      setMicIsLoading(false);
      setIsVoiceChatActive(true);
    }

    function handleCloseVoiceChat(event) {
      event.stopPropagation();

      trackEventGTM({
        event: "gaia_mute_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
        },
      });

      trackEventGA({
        event: "gaia_mute_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
        },
      });

      setMicIsLoading(true);
      if (!avatar.current) {
        setDebug("Avatar API not initialized");
        return;
      }

      avatar.current?.closeVoiceChat();
      setMicIsLoading(false);
      setIsVoiceChatActive(false);
    }

    async function handleSpeak() {
      setIsLoadingRepeat(true);
      if (!avatar.current) {
        setDebug("Avatar API not initialized");

        return;
      }
      await avatar.current
        .speak({
          text: text,
          taskType: TaskType.REPEAT,
          taskMode: TaskMode.SYNC,
        })
        .catch((e) => {
          setDebug(e.message);
        });
      setIsLoadingRepeat(false);
    }

    async function handleSpeakText() {
      setIsLoadingRepeat(true);
      if (!avatar.current) {
        setDebug("Avatar API not initialized");

        return;
      }
      await avatar.current
        .speak({
          text: textValue,
          taskType: TaskType.TALK,
          taskMode: TaskMode.SYNC,
        })
        .catch((e) => {
          setDebug(e.message);
        });
      setIsLoadingRepeat(false);
    }

    async function handleSpeakQuiz() {
      setIsLoadingRepeat(true);
      if (!avatar.current) {
        setDebug("Avatar API not initialized");

        return;
      }
      await avatar.current
        .speak({
          text: textQuiz,
          taskType: TaskType.TALK,
          taskMode: TaskMode.SYNC,
        })
        .catch((e) => {
          setDebug(e.message);
        });
      setIsLoadingRepeat(false);
    }

    async function handleInterrupt() {
      if (!avatar.current) {
        setDebug("Avatar API not initialized");

        return;
      }
      await avatar.current.interrupt().catch((e) => {
        setDebug(e.message);
      });
    }

    useImperativeHandle(ref, () => ({
      handleInterrupt,
    }));

    async function endSession() {
      if (!avatar.current) {
        setDebug("Avatar API not initialized");
        return;
      }

      trackEventGTM({
        event: "gaia_end_session_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
        },
      });

      trackEventGA({
        event: "gaia_end_session_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
          screen_state: isScrolled ? "minimize" : "maximize",
        },
      });

      avatar.current.closeVoiceChat();
      await avatar.current.stopAvatar();
      setText("");
      setStream(undefined);
      setStreamAvatar(undefined);
      setIsVoiceChatActive(false);
    }

    const handleChangeChatMode = useMemoizedFn(async (v) => {
      if (v === chatMode) {
        return;
      }

      setModeIsLoading(true);
      if (v === "text_mode") {
        avatar.current?.closeVoiceChat();
      } else {
        await avatar.current?.startVoiceChat();
      }
      setModeIsLoading(false);

      setChatMode(v);
    });

    useEffect(() => {
      if (isScrolled) {
        handleChangeChatMode("voice_mode");
      }
    }, [isScrolled]);

    const previousText = usePrevious(textValue);
    useEffect(() => {
      if (!previousText && textValue) {
        avatar.current?.startListening();
      } else if (previousText && !textValue) {
        avatar?.current?.stopListening();
      }
    }, [textValue, previousText]);

    useEffect(() => {
      return () => {
        endSession();
      };
    }, []);

    const [isMediaReady, setIsMediaReady] = useState(false);

    const isMalukuTenant =
      typeof window !== "undefined" && isMalukuURL(window.location.hostname);

    const tenantsAvatar = ["maluku-gate", "asuransiastra"];

    const initializeMediaStream = () => {
      if (mediaStream.current) {
        const previousScroll = window.scrollY;

        setDisabledMic(true);
        mediaStream.current.srcObject = null;
        mediaStream.current.src = `/images/${
          (tenantData as any)?.setting?.heygen_avatar
        }.mp4`;
        mediaStream.current.muted = true;
        mediaStream.current.loop = true;

        mediaStream.current.onloadedmetadata = () => {
          mediaStream.current!.play();
          window.scrollTo(0, previousScroll);
        };
      }
    };

    useEffect(() => {
      if (isMediaReady) {
        console.log("Stream Initialized");

        if (stream && mediaStream.current) {
          const previousScroll = window.scrollY;

          setDisabledMic(false);
          mediaStream.current.srcObject = stream;
          mediaStream.current.muted = false;
          mediaStream.current.loop = false;

          mediaStream.current.onloadedmetadata = () => {
            mediaStream.current!.play();
            window.scrollTo(0, previousScroll);
            setDebug("Playing");
          };
        } else {
          initializeMediaStream();
        }
      }
    }, [stream, isMediaReady]);

    const stopMediaTracks = (stream: MediaStream) => {
      stream.getTracks().forEach((track) => {
        if (track.readyState === "live" && track.kind === "audio") {
          track.stop(); // Hentikan track audio
        }
      });
    };

    useEffect(() => {
      return () => {
        // Pastikan stream audio dihentikan ketika komponen unmounted
        if (mediaRecorderRef.current) {
          const stream = mediaRecorderRef.current.stream;
          stopMediaTracks(stream);
        }
      };
    }, []);

    const [showAllSidebar, setShowAllSidebar] = useState(false);
    const {
      showSidebar,
      setShowSidebar,
      showSummarySidebar,
      setShowSummarySidebar,
      showQuizSidebar,
      setShowQuizSidebar,
    } = useAvatarStore();

    const toggleSidebar = (event) => {
      event.stopPropagation();

      trackEventGTM({
        event: showSidebar ? "gaia_transcript_close_a" : "gaia_transcript_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
        },
      });

      trackEventGA({
        event: showSidebar ? "gaia_transcript_close_a" : "gaia_transcript_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
        },
      });

      setShowSidebar(!showSidebar);
      setShowSummarySidebar(false);
      setShowQuizSidebar(false);
      setShowAllSidebar(false);
    };

    const toggleSummarySidebar = (event) => {
      event.stopPropagation();

      setShowSummarySidebar(!showSummarySidebar);
      setShowSidebar(false);
      setShowQuizSidebar(false);
      setShowAllSidebar(false);
    };

    const toggleQuizSidebar = (event) => {
      event.stopPropagation();

      setShowQuizSidebar(!showQuizSidebar);
      setShowSidebar(false);
      setShowSummarySidebar(false);
      setShowAllSidebar(false);
    };

    const [copySuccess, setCopySuccess] = useState({});

    const handleCopyText = (event, textToCopy, index) => {
      event.stopPropagation();

      trackEventGTM({
        event: "gaia_transcript_copy_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
        },
      });

      trackEventGA({
        event: "gaia_transcript_copy_a",
        property: {
          user_id: currentUser?.id ?? "",
          is_logged_in: currentUser ? true : false,
          session_id: dataAvatar?.session_id,
        },
      });

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopySuccess((prevState) => ({
            ...prevState,
            [index]: true,
          }));

          setTimeout(() => {
            setCopySuccess((prevState) => ({
              ...prevState,
              [index]: false,
            }));
          }, 2000);
        })
        .catch(() => {
          setCopySuccess((prevState) => ({
            ...prevState,
            [index]: false,
          }));
        });
    };

    const scrollToTop = (event) => {
      event.stopPropagation();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const handleBigger = (event) => {
      event.stopPropagation();

      setIsSmaller(false);
    };

    const handleSmaller = (event) => {
      event.stopPropagation();

      setIsSmaller(true);
    };

    useEffect(() => {
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }, [copySuccess]);

    const [volumes, setVolumes] = useState<number[]>(Array(10).fill(0));
    const [audioStream, setAudioStream] = useState<MediaStream | null>(null);

    // const { isSupported, isEnabled, setEnabled } = useNoiseCancellation();

    useEffect(() => {
      const handleAudio = async () => {
        try {
          // if (isSupported === false) {
          //   console.warn("Noise cancellation is not supported on this platform.");
          //   return;
          // }

          const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          });
          setAudioStream(stream);

          const audioContext = new (window.AudioContext ||
            (window as any).webkitAudioContext)();
          //     {
          //   sampleRate:
          //     stream.getAudioTracks()[0].getSettings().sampleRate || 44100,
          // }

          const analyser = audioContext.createAnalyser();
          const source = audioContext.createMediaStreamSource(stream);
          source.connect(analyser);
          analyser.fftSize = 256;

          const dataArray = new Uint8Array(analyser.frequencyBinCount);

          const updateVolume = () => {
            analyser.getByteFrequencyData(dataArray);

            if (isVoiceChatActive) {
              const newVolumes = Array.from(
                { length: 10 },
                (_, i) => dataArray[i * 2] / 2
              );
              setVolumes(newVolumes);
            }

            requestAnimationFrame(updateVolume);
          };

          updateVolume();
        } catch (err) {
          console.error("Error accessing microphone:", err);
        }
      };

      handleAudio();

      return () => {
        if (audioStream) {
          audioStream.getTracks().forEach((track) => track.stop());
        }
      };
    }, [
      isVoiceChatActive,
      // , isSupported
    ]);

    useEffect(() => {
      if (!isVoiceChatActive && audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    }, [isVoiceChatActive, audioStream]);

    const chatEndRef = useRef(null);
    const summaryEndRef = useRef(null);
    const summaryRecapRef = useRef(null);

    useEffect(() => {
      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }, [chat.chat, isScrolled, showSidebar]);

    useEffect(() => {
      if (summaryEndRef.current) {
        summaryEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }, [historySummary.summary, isScrolled, showSummarySidebar, activeTab]);

    useEffect(() => {
      if (summaryRecapRef.current) {
        summaryRecapRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }, [summaryRecap.summary, isScrolled, showSummarySidebar, activeTab]);

    // useEffect(() => {
    //   setShowSidebar(false);
    //   setShowSummarySidebar(false);
    //   setShowAllSidebar(false);
    // }, [isScrolled]);

    const requestQuota = useRequestAvatarQuota();

    const handleRequestQuota = async (e) => {
      e.stopPropagation();

      requestQuota.doFetch(
        {
          slug: currentUser?.referral,
        },
        {
          onSuccess: (data) => {
            setIsRequestSent(true);
            showToast({
              type: "info",
              message: "Request Terkirim",
            });
          },
        }
      );
    };

    useEffect(() => {
      if (currentUser?.has_request_quota_gaia) {
        setIsRequestSent(true);
      }
    }, [currentUser]);

    // @ts-ignore
    const getSubdomainAPI = useGetSubdomainData();
    const { tenantData, setTenantData } = useAuthStore();

    useEffect(() => {
      getSubdomainAPI.doFetch(
        {
          slug:
            window.location.hostname === "e-learning.bluebirdgroup.com"
              ? "bluebird"
              : currentUser?.referral ?? subDomain,
        },
        {
          onSuccess(data: any) {
            console.log(data);
            setTenantData(data?.data);
          },
        }
      );
    }, [currentUser, subDomain]);

    const [isHeygenEnabled, setIsHeygenEnabled] = useState(false);

    useEffect(() => {
      if (CONST.IS_DEV) {
        setIsHeygenEnabled(true);
      } else {
        if ((tenantData as any)?.setting?.is_heygen_enabled) {
          setIsHeygenEnabled(true);
        } else {
          setIsHeygenEnabled(false);
        }
      }
      console.log(tenantData);
    }, [CONST, tenantData]);

    useEffect(() => {
      if (isHeygenEnabled) {
        trackEventGTM({
          event: "gaia_homepage_s",
          property: {
            user_id: currentUser?.id ?? "",
            is_logged_in: currentUser ? true : false,
          },
        });

        trackEventGA({
          event: "gaia_homepage_s",
          property: {
            user_id: currentUser?.id ?? "",
            is_logged_in: currentUser ? true : false,
          },
        });
      }
    }, [isHeygenEnabled, currentUser]);

    useEffect(() => {
      if (!currentUser && stream) {
        endSession();
      }
    }, [currentUser]);

    return (
      <>
        {!isDesktop && isHeygenEnabled && !isCourse && (
          <AvatarMobile
            handleInterrupt={handleInterrupt}
            dataAvatar={dataAvatar}
            isAnyFiltered={isAnyFiltered}
            isKeywordsSearched={isKeywordsSearched}
            isScrolled={isScrolled}
            handleMinimize={handleMinimize}
            isSmaller={isSmaller}
            mediaStream={mediaStream}
            isMediaReady={isMediaReady}
            setIsMediaReady={setIsMediaReady}
            startSession={startSession}
            endSession={endSession}
            isLoadingSession={isLoadingSession}
            stream={stream}
            toggleSidebar={toggleSidebar}
            showSidebar={showSidebar}
            toggleSummarySidebar={toggleSummarySidebar}
            showSummarySidebar={showSummarySidebar}
            toggleQuizSidebar={toggleQuizSidebar}
            showQuizSidebar={showQuizSidebar}
            chat={chat}
            lastDisplayedDate={lastDisplayedDate}
            currentUser={currentUser}
            handleCopyText={handleCopyText}
            copySuccess={copySuccess}
            chatEndRef={chatEndRef}
            historySummary={historySummary}
            summaryRecap={summaryRecap}
            summaryEndRef={summaryEndRef}
            scrollToTop={scrollToTop}
            handleBigger={handleBigger}
            handleSmaller={handleSmaller}
            volumes={volumes}
            isVoiceChatActive={isVoiceChatActive}
            handleCloseVoiceChat={handleCloseVoiceChat}
            handleStartVoiceChat={handleStartVoiceChat}
            disabledMic={disabledMic}
            micIsLoading={micIsLoading}
            modeIsLoading={modeIsLoading}
            remainingDuration={remainingDuration}
            maxDuration={maxDuration}
            formatTime={formatTime}
            showLimitDesc={showLimitDesc}
            setShowLimitDesc={setShowLimitDesc}
            isRequestSent={isRequestSent}
            setIsRequestSent={setIsRequestSent}
            isIncreaseLimit={isIncreaseLimit}
            setIsIncreaseLimit={setIsIncreaseLimit}
            limitChecked={limitChecked}
            setLimitChecked={setLimitChecked}
            showAllSidebar={showAllSidebar}
            setShowAllSidebar={setShowAllSidebar}
            handleFullScreen={handleFullScreen}
            showToast={showToast}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleRequestQuota={handleRequestQuota}
            chatMode={chatMode}
            isUserTalking={isUserTalking}
            textValue={textValue}
            setTextValue={setTextValue}
            handleSpeakText={handleSpeakText}
            isLoadingRepeat={isLoadingRepeat}
            handleChangeChatMode={handleChangeChatMode}
            saveChat={saveChat}
            textQuiz={textQuiz}
            setTextQuiz={setTextQuiz}
            handleSpeakQuiz={handleSpeakQuiz}
          />
        )}

        {isDesktop && isHeygenEnabled && !isCourse && (
          <AvatarDesktop
            handleInterrupt={handleInterrupt}
            dataAvatar={dataAvatar}
            isAnyFiltered={isAnyFiltered}
            isKeywordsSearched={isKeywordsSearched}
            isScrolled={isScrolled}
            handleMinimize={handleMinimize}
            isSmaller={isSmaller}
            mediaStream={mediaStream}
            isMediaReady={isMediaReady}
            setIsMediaReady={setIsMediaReady}
            startSession={startSession}
            endSession={endSession}
            isLoadingSession={isLoadingSession}
            stream={stream}
            toggleSidebar={toggleSidebar}
            showSidebar={showSidebar}
            toggleSummarySidebar={toggleSummarySidebar}
            showSummarySidebar={showSummarySidebar}
            toggleQuizSidebar={toggleQuizSidebar}
            showQuizSidebar={showQuizSidebar}
            chat={chat}
            lastDisplayedDate={lastDisplayedDate}
            currentUser={currentUser}
            handleCopyText={handleCopyText}
            copySuccess={copySuccess}
            chatEndRef={chatEndRef}
            historySummary={historySummary}
            summaryRecap={summaryRecap}
            summaryEndRef={summaryEndRef}
            scrollToTop={scrollToTop}
            handleBigger={handleBigger}
            handleSmaller={handleSmaller}
            volumes={volumes}
            isVoiceChatActive={isVoiceChatActive}
            handleCloseVoiceChat={handleCloseVoiceChat}
            handleStartVoiceChat={handleStartVoiceChat}
            disabledMic={disabledMic}
            micIsLoading={micIsLoading}
            modeIsLoading={modeIsLoading}
            remainingDuration={remainingDuration}
            maxDuration={maxDuration}
            formatTime={formatTime}
            showLimitDesc={showLimitDesc}
            setShowLimitDesc={setShowLimitDesc}
            isRequestSent={isRequestSent}
            setIsRequestSent={setIsRequestSent}
            isIncreaseLimit={isIncreaseLimit}
            setIsIncreaseLimit={setIsIncreaseLimit}
            limitChecked={limitChecked}
            setLimitChecked={setLimitChecked}
            showAllSidebar={showAllSidebar}
            setShowAllSidebar={setShowAllSidebar}
            handleFullScreen={handleFullScreen}
            showToast={showToast}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleRequestQuota={handleRequestQuota}
            chatMode={chatMode}
            isUserTalking={isUserTalking}
            textValue={textValue}
            setTextValue={setTextValue}
            handleSpeakText={handleSpeakText}
            isLoadingRepeat={isLoadingRepeat}
            handleChangeChatMode={handleChangeChatMode}
            saveChat={saveChat}
            textQuiz={textQuiz}
            setTextQuiz={setTextQuiz}
            handleSpeakQuiz={handleSpeakQuiz}
          />
        )}
      </>
    );
  }
);
