import IconClose from "components/Icon/IconClose";
import Image from "next/image";
import { motion } from "framer-motion";
import LoaderFadeInOut from "components/Loader/LoaderFadeInOut";
import { Limit } from "./Limit";
import { useEffect, useRef, useState } from "react";
import { useAvatarStore } from "./avatarStore";
import { formatDate } from "lib/formatDate";
import { useRouter } from "next/router";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AudioVisualizer } from "./AudioVisualizer";
import { BottomNavMobile } from "./BottomNavMobile";
import { trackEventGA, trackEventGTM } from "lib/amplitude";
import clsx from "clsx";
import { usePreventScroll } from "./usePreventScroll";
import { useAuthStore } from "modules/Auth/authStore";

export const AvatarMobile = ({
  handleInterrupt,
  dataAvatar,
  isAnyFiltered,
  isKeywordsSearched,
  isScrolled,
  handleMinimize,
  isSmaller,
  mediaStream,
  isMediaReady,
  setIsMediaReady,
  startSession,
  endSession,
  isLoadingSession,
  stream,
  toggleSidebar,
  showSidebar,
  toggleSummarySidebar,
  showSummarySidebar,
  toggleQuizSidebar,
  showQuizSidebar,
  chat,
  lastDisplayedDate,
  currentUser,
  handleCopyText,
  copySuccess,
  chatEndRef,
  historySummary,
  summaryRecap,
  summaryEndRef,
  scrollToTop,
  handleBigger,
  handleSmaller,
  volumes,
  isVoiceChatActive,
  handleCloseVoiceChat,
  handleStartVoiceChat,
  disabledMic,
  micIsLoading,
  modeIsLoading,
  remainingDuration,
  maxDuration,
  formatTime,
  showLimitDesc,
  setShowLimitDesc,
  isRequestSent,
  setIsRequestSent,
  isIncreaseLimit,
  setIsIncreaseLimit,
  limitChecked,
  setLimitChecked,
  showAllSidebar,
  setShowAllSidebar,
  handleFullScreen,
  showToast,
  activeTab,
  setActiveTab,
  handleRequestQuota,
  chatMode,
  isUserTalking,
  textValue,
  setTextValue,
  handleSpeakText,
  isLoadingRepeat,
  handleChangeChatMode,
  saveChat,
  textQuiz,
  setTextQuiz,
  handleSpeakQuiz,
}) => {
  usePreventScroll();

  const router = useRouter();
  const [showAdditionalContent, setShowAdditionalContent] = useState(true);

  const inputRef = useRef(null);

  const handleFocus = () => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleSubmit = () => {
    if (textValue.trim() === "") {
      return;
    }
    handleSpeakText();
    setTextValue("");
  };

  useEffect(() => {
    setShowAdditionalContent(false);
    const timeout = setTimeout(() => {
      setShowAdditionalContent(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [isScrolled]);

  const containerRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    Promise.all([import("gsap"), import("gsap/ScrollTrigger")]).then(
      ([{ default: gsap }, { ScrollTrigger }]) => {
        gsap.registerPlugin(ScrollTrigger);

        const videoContainer = videoContainerRef.current;

        if (!stream) {
          gsap.set(videoContainer, {
            width: "0px",
            height: "0px",
            bottom: 0,
            right: 0,
            position: "fixed",
          });
        } else if (stream && isScrolled) {
          gsap.set(videoContainer, {
            width: "100%",
            height: "calc(100vh - 68px)",
            bottom: 0,
            right: 0,
            position: "fixed",
          });
        } else {
          gsap.set(videoContainer, {
            width: "47vw",
            height: isSmaller ? "120px" : "30vh",
            bottom: 80,
            right: 10,
            position: "fixed",
          });
        }

        if (stream) {
          const tl = gsap.timeline({
            defaults: { duration: 1, ease: "power2.inOut" },
          });

          if (isScrolled) {
            tl.to(videoContainer, {
              width: "47vw",
              height: isSmaller ? "120px" : "30vh",
              bottom: 80,
              right: 10,
              position: "fixed",
            });
          } else {
            tl.to(videoContainer, {
              width: "100%",
              height: "calc(100vh - 68px)",
              bottom: 0,
              right: 0,
              zIndex: 100,
              position: "fixed",
            });
          }

          tl.play();

          return () => {
            tl.kill();
          };
        }
      }
    );
  }, [isScrolled, isSmaller, stream]);

  const [isQuizStarted, setIsQuizStarted] = useState(false);

  const excludedWords = [
    "ketentuan",
    "trigger",
    "skor",
    "grade",
    "passing",
    "penilaian",
    "lolos",
    "gaia",
    "jika anda",
    "memiliki pertanyaan",
  ];

  const includeWords = [":"];

  const filterChatWithQuestion = saveChat.chat.filter((item) => {
    const avatarText = item.avatar?.toLowerCase();

    const containsExcludedWord = excludedWords.some((word) =>
      avatarText.includes(word)
    );

    const containsIncludeWord = includeWords.some((word) =>
      avatarText.includes(word)
    );

    return (
      avatarText.includes("pertanyaan") &&
      containsIncludeWord &&
      !containsExcludedWord
    );
  });

  const fetchHandleSpeakQuiz = async () => {
    await handleSpeakQuiz();
  };

  useEffect(() => {
    if (
      saveChat.chat.length % 3 === 0 &&
      saveChat.chat.length !== 0 &&
      !isQuizStarted
    ) {
      setTextQuiz(`
        Quiz Trigger Conditions:

        - Apakah pengguna telah mengajukan tiga pertanyaan pada topik diskusi yang sama terkait pekerjaan profesional?
        - Apakah pengguna telah beralih ke topik lain, meskipun mereka hanya mengajukan satu pertanyaan relevan pada topik sebelumnya?

        Jika ya, maka GAIA akan memulai sesi Quiz.
      `);

      setTimeout(() => {
        fetchHandleSpeakQuiz();
      }, 3000);
    }
  }, [saveChat.chat]);

  useEffect(() => {
    if (filterChatWithQuestion.length > 0) {
      setIsQuizStarted(true);
    }
  }, [filterChatWithQuestion.length]);

  useEffect(() => {
    if (!stream) {
      setIsQuizStarted(false);
    }
  }, [stream]);

  const { tenantData } = useAuthStore();

  return (
    <>
      {!stream && (
        <div className="fixed bottom-80 right-6 z-50">
          <div
            className="relative cursor-pointer"
            onClick={
              remainingDuration > 0 && currentUser
                ? startSession
                : remainingDuration > 0 && !currentUser
                ? () => {
                    const currentPath = router.asPath;
                    router.replace({
                      pathname: "/login",
                      query: {
                        url: encodeURIComponent(currentPath),
                      },
                    });
                  }
                : remainingDuration <= 0 && currentUser
                ? (e) => {
                    e.stopPropagation();

                    trackEventGTM({
                      event: "gaia_quota_s",
                      property: {
                        user_id: currentUser?.id ?? "",
                        is_logged_in: currentUser ? true : false,
                        session_id: dataAvatar?.session_id,
                        screen_state: isScrolled ? "minimize" : "maximize",
                      },
                    });

                    trackEventGA({
                      event: "gaia_quota_s",
                      property: {
                        user_id: currentUser?.id ?? "",
                        is_logged_in: currentUser ? true : false,
                        session_id: dataAvatar?.session_id,
                        screen_state: isScrolled ? "minimize" : "maximize",
                      },
                    });

                    setShowLimitDesc(!showLimitDesc);
                  }
                : () => {
                    const currentPath = router.asPath;
                    router.replace({
                      pathname: "/login",
                      query: {
                        url: encodeURIComponent(currentPath),
                      },
                    });
                  }
            }
          >
            <Image
              width={130}
              height={128}
              src="/images/gaia-box-widget.png"
              alt="gaia-box-widget"
            />

            <video
              className="absolute top-12 right-20 w-[98px] h-[72px] rounded-[19px] object-cover"
              src={`/images/${(tenantData as any)?.setting?.heygen_avatar}.mp4`}
              loop
              playsInline
              autoPlay
            ></video>

            {isLoadingSession && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="absolute rounded-[19px] z-50 top-12 right-20"
                style={{
                  width: "98px",
                  height: "72px",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="relative -bottom-28 -right-38">
                  <LoaderFadeInOut />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        ref={containerRef}
        className={`${
          isScrolled && stream
            ? "fixed w-[10px] h-[10px] z-30"
            : !isScrolled && stream
            ? "fixed w-full h-[calc(100vh-68px)] z-[100]"
            : "fixed w-[10px] h-[10px] z-30"
        } inset-0 transition-all duration-500 ease-in-out mb-8 opacity-100`}
      >
        <div
          ref={videoContainerRef}
          className={`${
            isScrolled && !isSmaller
              ? "fixed bottom-80 right-10"
              : isScrolled && isSmaller
              ? "fixed bottom-80 right-10"
              : "fixed bottom-0 w-full h-[calc(100vh-68px)]"
          } flex items-center justify-center`}
        >
          <video
            webkit-playsinline="true"
            playsInline
            onClick={(e) => {
              e.stopPropagation();

              if (!currentUser) {
                const currentPath = router.asPath;
                router.replace({
                  pathname: "/login",
                  query: {
                    url: encodeURIComponent(currentPath),
                  },
                });
              }
            }}
            className={`${
              isScrolled
                ? "w-full h-full rounded-xl"
                : "w-full h-[calc(100vh-68px)]"
            } object-cover transition-all duration-500 ease-in-out`}
            style={{ boxShadow: "4px 4px 12px 0px #0000004D" }}
            id="sm-video"
            ref={(ref) => {
              mediaStream.current = ref;
              if (ref && !isMediaReady) {
                setIsMediaReady(true);
              }
            }}
          />

          {stream && showAdditionalContent && (
            <>
              <motion.div
                className={`absolute top-0 left-0 ${
                  !isScrolled ? "" : "rounded-tl-xl"
                } px-16 py-10 rounded-br-xl flex justify-center gap-8 z-30`}
                style={{
                  background:
                    "linear-gradient(270deg, rgba(85, 31, 193, 0.8) 0%, rgba(243, 122, 65, 0.8) 100%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  if (!currentUser) {
                    const currentPath = router.asPath;
                    router.replace({
                      pathname: "/login",
                      query: {
                        url: encodeURIComponent(currentPath),
                      },
                    });
                  }
                }}
              >
                <Image
                  src={"/images/star-gaia.svg"}
                  alt="gaia"
                  width={16}
                  height={16}
                />
                <p className="text-white_to_white text-b1 font-medium">GAIA</p>
                {!isScrolled && (
                  <p className="text-white_to_white text-b1 font-light">
                    | goKampus AI Advisor
                  </p>
                )}
              </motion.div>

              {!isSmaller && (
                <motion.div
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!currentUser) {
                      const currentPath = router.asPath;
                      router.replace({
                        pathname: "/login",
                        query: {
                          url: encodeURIComponent(currentPath),
                        },
                      });
                    }
                  }}
                  className={`absolute bottom-0 ${
                    isScrolled && "rounded-b-xl"
                  } h-64 w-full`}
                  style={{
                    backdropFilter: "blur(64px)",
                    backgroundColor: "#FFFFFF14",
                  }}
                ></motion.div>
              )}

              {(!isSmaller || !isScrolled) && (
                <motion.div
                  className={`absolute left-8 bottom-80 opacity-50 min-w-[45%] gap-8 items-center pt-10 rounded-full flex`}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!currentUser) {
                      const currentPath = router.asPath;
                      router.replace({
                        pathname: "/login",
                        query: {
                          url: encodeURIComponent(currentPath),
                        },
                      });
                    }
                  }}
                >
                  <p className="text-white_to_white text-c1 font-light">
                    Powered by
                  </p>
                  <Image
                    src={"/images/logo-nxtx-dark.png"}
                    alt="nxtx"
                    width={66}
                    height={11}
                    className={isScrolled && "grow"}
                  />
                </motion.div>
              )}

              {!stream && !isScrolled && (
                <motion.div
                  className={`absolute bottom-120 flex flex-col gap-16 justify-center items-center text-center`}
                >
                  <h4 className="text-white_to_white text-h4 max-w-[300px] font-medium">
                    Upskill Kompetensi Profesional Anda dengan AI Interaktif
                  </h4>
                  <p className="text-white_to_white text-c1 max-w-[300px] font-light">
                    Bertanya, berdiskusi dan dapatkan feedback secara langsung
                    tanpa tunggu
                  </p>
                </motion.div>
              )}

              {!isScrolled && (
                <motion.div
                  className={`absolute bottom-8 right-8 p-10 rounded-full cursor-pointer z-50`}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.44)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    setShowAllSidebar(!showAllSidebar);
                  }}
                >
                  <Image
                    src={"/images/hamburger-point.svg"}
                    alt="hamburger-point"
                    width={24}
                    height={24}
                  />
                </motion.div>
              )}

              {!isScrolled ? (
                <>
                  {showAllSidebar && (
                    <motion.div
                      className={`fixed bottom-70 text-white_to_white right-10 p-10 rounded-16 cursor-pointer z-[200]`}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                        backdropFilter: "blur(24px)",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();

                        setShowAllSidebar(!showAllSidebar);
                      }}
                    >
                      {/* {isQuizStarted && (
                        <motion.div
                          className="p-4 text-b2 font-light"
                          onClick={
                            currentUser
                              ? toggleQuizSidebar
                              : () => {
                                  const currentPath = router.asPath;
                                  router.replace({
                                    pathname: "/login",
                                    query: {
                                      url: encodeURIComponent(currentPath),
                                    },
                                  });
                                }
                          }
                        >
                          Quiz
                        </motion.div>
                      )} */}

                      <motion.div
                        className="p-4 text-b2 font-light"
                        onClick={
                          currentUser
                            ? toggleSidebar
                            : () => {
                                const currentPath = router.asPath;
                                router.replace({
                                  pathname: "/login",
                                  query: {
                                    url: encodeURIComponent(currentPath),
                                  },
                                });
                              }
                        }
                      >
                        Transcript
                      </motion.div>
                      <motion.div
                        className="p-4 text-b2 font-light"
                        onClick={
                          currentUser
                            ? toggleSummarySidebar
                            : () => {
                                const currentPath = router.asPath;
                                router.replace({
                                  pathname: "/login",
                                  query: {
                                    url: encodeURIComponent(currentPath),
                                  },
                                });
                              }
                        }
                      >
                        Summary
                      </motion.div>
                    </motion.div>
                  )}

                  <BottomNavMobile
                    isQuizStarted={isQuizStarted}
                    setIsQuizStarted={setIsQuizStarted}
                    saveChat={saveChat}
                    filterChatWithQuestion={filterChatWithQuestion}
                    textValue={textValue}
                    setTextValue={setTextValue}
                    handleSpeakText={handleSpeakText}
                    showSidebar={showSidebar}
                    toggleSidebar={toggleSidebar}
                    chat={chat}
                    lastDisplayedDate={lastDisplayedDate}
                    currentUser={currentUser}
                    handleCopyText={handleCopyText}
                    copySuccess={copySuccess}
                    chatEndRef={chatEndRef}
                    showSummarySidebar={showSummarySidebar}
                    toggleSummarySidebar={toggleSummarySidebar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    stream={stream}
                    historySummary={historySummary}
                    summaryRecap={summaryRecap}
                    summaryEndRef={summaryEndRef}
                    showQuizSidebar={showQuizSidebar}
                    toggleQuizSidebar={toggleQuizSidebar}
                    customClassQuiz="absolute rounded-tl-xl rounded-tr-xl w-full h-auto bottom-0 px-16 pb-72 pt-58 transition-all duration-700 ease-in-out"
                    customClassTranscript="absolute rounded-tl-xl rounded-tr-xl w-full h-[50%] bottom-0 px-16 pb-72 pt-48 transition-all duration-700 ease-in-out"
                    customClassSummary="absolute rounded-tl-xl rounded-tr-xl w-full h-[50%] bottom-0 px-16 pb-72 pt-48 transition-all duration-700 ease-in-out"
                  />

                  <motion.div
                    className={`absolute top-4 right-4 p-10 rounded-full cursor-pointer hover:bg-[rgba(0,0,0,0.44)]`}
                    onClick={endSession}
                  >
                    <Image
                      src="/images/arrow-down.svg"
                      alt="arrow"
                      width={24}
                      height={24}
                    />
                  </motion.div>
                </>
              ) : (
                <motion.div
                  className={`absolute ${
                    isScrolled && !isSmaller
                      ? "top-4"
                      : isScrolled && isSmaller
                      ? "bottom-8"
                      : "bottom-30"
                  } right-4 p-10 rounded-full cursor-pointer hover:bg-[rgba(0,0,0,0.44)]`}
                  onClick={handleFullScreen}
                >
                  <Image
                    src={"/images/fullscreen.svg"}
                    alt="fullscreen"
                    width={24}
                    height={24}
                  />
                </motion.div>
              )}

              {stream ? (
                <motion.div
                  className={`absolute ${
                    isScrolled ? "bottom-12 left-8" : "bottom-8 left-8"
                  } flex items-center gap-4 transition-all z-30`}
                >
                  <motion.div
                    className={`bg-white_to_white ${
                      isScrolled ? "w-[35vw]" : "w-[64vw]"
                    } rounded-full flex items-center transition-all duration-500 z-30`}
                  >
                    {chatMode === "text_mode" ? (
                      <div className="relative w-full flex justify-between">
                        <input
                          ref={inputRef}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          autoFocus
                          type="text"
                          placeholder="Ketik di sini"
                          value={textValue}
                          onChange={(e) => setTextValue(e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleInterrupt();
                              handleSubmit();
                            }
                          }}
                          disabled={!stream}
                          className={clsx(
                            "h-[35px] w-full pl-20 pr-40 text-b2 text-[#19093A] font-light rounded-full ring-2 ring-purple50_to_purple50 focus:outline-none",
                            isLoadingRepeat && "pr-10"
                          )}
                        />

                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInterrupt();
                            handleSubmit();
                          }}
                          className="absolute top-1/2 -translate-y-1/2 right-10 cursor-pointer bg-white_to_white"
                        >
                          <Image
                            src={
                              textValue.length > 0
                                ? "/images/send.svg"
                                : "/images/keyboard.svg"
                            }
                            alt={textValue.length > 0 ? "send" : "keyboard"}
                            width={24}
                            height={24}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {remainingDuration <= 120 && remainingDuration >= 0 && (
                          <motion.div
                            className="border-2 border-white_to_white rounded-full cursor-pointer py-12 px-6 flex justify-between items-center gap-4 text-white_to_white text-b2 font-medium"
                            style={{
                              backgroundColor: "#D0375CCC",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();

                              setShowLimitDesc(!showLimitDesc);
                            }}
                          >
                            {formatTime(remainingDuration)}

                            <Image
                              className="min-h-16 min-w-16"
                              src={"/images/countdown-info.svg"}
                              alt="countdown"
                              width={16}
                              height={16}
                            />
                          </motion.div>
                        )}

                        {(!(
                          remainingDuration <= 120 && remainingDuration >= 0
                        ) ||
                          (remainingDuration <= 120 &&
                            remainingDuration >= 0 &&
                            !isScrolled)) && (
                          <AudioVisualizer
                            volumes={volumes}
                            customClass={`w-full ${
                              !(
                                remainingDuration <= 120 &&
                                remainingDuration >= 0
                              ) && "translate-x-[5%] px-4"
                            } flex justify-center items-center gap-8 ${
                              isScrolled ? "" : "px-16"
                            } py-10 rounded-full bg-transparent`}
                          />
                        )}

                        <button
                          // disabled={disabledMic}
                          id="mic-button"
                          onClick={
                            isVoiceChatActive
                              ? handleCloseVoiceChat
                              : handleStartVoiceChat
                          }
                          className={`opacity-100 hover:cursor-pointer p-8 transition-all duration-500 ease-in-out`}
                        >
                          <Image
                            src={`${
                              isVoiceChatActive
                                ? "/images/Microphone-On.svg"
                                : "/images/Microphone-Off-Purple.svg"
                            }`}
                            alt="mic"
                            width={24}
                            height={24}
                            className="min-w-24 min-h-24"
                          />
                        </button>

                        {micIsLoading && (
                          <motion.div
                            onClick={(e) => e.stopPropagation()}
                            className={`absolute ${
                              isScrolled ? "right-0" : "right-48"
                            } rounded-full z-50`}
                            style={{
                              width: "44px",
                              height: "44px",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            <motion.div className="relative -bottom-10 -right-18">
                              <LoaderFadeInOut />
                            </motion.div>
                          </motion.div>
                        )}
                      </>
                    )}
                  </motion.div>

                  {!isScrolled && (
                    <div className="relative">
                      <motion.div
                        className={`${
                          chatMode === "text_mode"
                            ? "bg-purple50_to_purple50"
                            : "bg-white_to_white"
                        } rounded-full flex items-center gap-2 transition-all duration-500 z-30`}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (chatMode === "text_mode") {
                              trackEventGTM({
                                event: "gaia_mic_a",
                                property: {
                                  user_id: currentUser?.id ?? "",
                                  is_logged_in: currentUser ? true : false,
                                  session_id: dataAvatar?.session_id,
                                },
                              });

                              trackEventGA({
                                event: "gaia_mic_a",
                                property: {
                                  user_id: currentUser?.id ?? "",
                                  is_logged_in: currentUser ? true : false,
                                  session_id: dataAvatar?.session_id,
                                },
                              });

                              handleChangeChatMode("voice_mode");
                            } else {
                              trackEventGTM({
                                event: "gaia_text_a",
                                property: {
                                  user_id: currentUser?.id ?? "",
                                  is_logged_in: currentUser ? true : false,
                                  session_id: dataAvatar?.session_id,
                                },
                              });

                              trackEventGA({
                                event: "gaia_text_a",
                                property: {
                                  user_id: currentUser?.id ?? "",
                                  is_logged_in: currentUser ? true : false,
                                  session_id: dataAvatar?.session_id,
                                },
                              });

                              handleChangeChatMode("text_mode");
                            }
                          }}
                          id="mode-button"
                          className={`opacity-100 hover:cursor-pointer p-10 transition-all duration-500 ease-in-out`}
                        >
                          <Image
                            src={`${
                              chatMode === "text_mode"
                                ? "/images/Microphone-On-White.svg"
                                : "/images/keyboard.svg"
                            }`}
                            alt="mode"
                            width={24}
                            height={24}
                          />
                        </button>
                      </motion.div>

                      {modeIsLoading && (
                        <motion.div
                          onClick={(e) => e.stopPropagation()}
                          className={`absolute bottom-0 right-0 rounded-full z-50`}
                          style={{
                            width: "44px",
                            height: "44px",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                          }}
                        >
                          <motion.div className="relative -bottom-10 -right-18">
                            <LoaderFadeInOut />
                          </motion.div>
                        </motion.div>
                      )}
                    </div>
                  )}
                </motion.div>
              ) : (
                <>
                  {!isSmaller && (
                    <>
                      {remainingDuration > 120 && (
                        <motion.div
                          className={`absolute ${
                            isScrolled ? "bottom-8" : "bottom-10"
                          } flex items-center gap-2 transition-all duration-500`}
                        >
                          <button
                            id="start-button"
                            disabled={remainingDuration <= 0}
                            onClick={
                              currentUser
                                ? startSession
                                : () => {
                                    const currentPath = router.asPath;
                                    router.replace({
                                      pathname: "/login",
                                      query: {
                                        url: encodeURIComponent(currentPath),
                                      },
                                    });
                                  }
                            }
                            className={`opacity-100 rounded-full py-10 px-16 flex gap-2 items-center transition-all duration-500 ease-in-out bg-white_to_white text-purple50_to_purple50 text-b2 font-medium ${
                              remainingDuration <= 0
                                ? "cursor-not-allowed opacity-50"
                                : "cursor-pointer"
                            }`}
                          >
                            <Image
                              src="/images/play.svg"
                              alt="play"
                              width={24}
                              height={24}
                            />
                            Start Session
                          </button>
                        </motion.div>
                      )}

                      {remainingDuration <= 120 && remainingDuration >= 0 && (
                        <>
                          {currentUser ? (
                            <motion.div
                              className={`absolute ${
                                isScrolled
                                  ? "bottom-8 -translate-x-full"
                                  : !isScrolled &&
                                    remainingDuration <= 120 &&
                                    remainingDuration >= 0
                                  ? "bottom-10 -translate-x-full"
                                  : "bottom-10"
                              } flex items-center gap-4 transition-all z-30`}
                            >
                              <motion.div className="flex items-center gap-2 transition-all duration-500 z-30">
                                <button
                                  id="start-button"
                                  // disabled={remainingDuration <= 0}
                                  onClick={
                                    remainingDuration > 0 && currentUser
                                      ? startSession
                                      : remainingDuration > 0 && !currentUser
                                      ? () => {
                                          const currentPath = router.asPath;
                                          router.replace({
                                            pathname: "/login",
                                            query: {
                                              url: encodeURIComponent(
                                                currentPath
                                              ),
                                            },
                                          });
                                        }
                                      : remainingDuration <= 0 && currentUser
                                      ? (e) => {
                                          e.stopPropagation();

                                          trackEventGTM({
                                            event: "gaia_quota_s",
                                            property: {
                                              user_id: currentUser?.id ?? "",
                                              is_logged_in: currentUser
                                                ? true
                                                : false,
                                              session_id:
                                                dataAvatar?.session_id,
                                              screen_state: isScrolled
                                                ? "minimize"
                                                : "maximize",
                                            },
                                          });

                                          trackEventGA({
                                            event: "gaia_quota_s",
                                            property: {
                                              user_id: currentUser?.id ?? "",
                                              is_logged_in: currentUser
                                                ? true
                                                : false,
                                              session_id:
                                                dataAvatar?.session_id,
                                              screen_state: isScrolled
                                                ? "minimize"
                                                : "maximize",
                                            },
                                          });

                                          setShowLimitDesc(!showLimitDesc);
                                        }
                                      : () => {
                                          const currentPath = router.asPath;
                                          router.replace({
                                            pathname: "/login",
                                            query: {
                                              url: encodeURIComponent(
                                                currentPath
                                              ),
                                            },
                                          });
                                        }
                                  }
                                  className={`opacity-100 rounded-full p-10 flex gap-2 items-center transition-all duration-500 ease-in-out bg-white_to_white text-purple50_to_purple50 text-b2 font-medium ${
                                    remainingDuration <= 0
                                      ? "cursor-not-allowed"
                                      : "cursor-pointer"
                                  }`}
                                >
                                  <Image
                                    src="/images/play.svg"
                                    alt="play"
                                    width={24}
                                    height={24}
                                  />
                                </button>
                              </motion.div>

                              {!isSmaller && (
                                <motion.div className={`absolute gap-8`}>
                                  <motion.div
                                    className="rounded-full cursor-pointer pl-50 py-12 pr-30 flex items-center gap-8 text-white_to_white text-b2 font-light"
                                    style={{
                                      backgroundColor: "#D0375CCC",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      setShowLimitDesc(!showLimitDesc);
                                    }}
                                  >
                                    {formatTime(remainingDuration)}

                                    <Image
                                      className="h-16 w-16"
                                      src={"/images/countdown-info.svg"}
                                      alt="countdown"
                                      width={16}
                                      height={16}
                                    />
                                  </motion.div>
                                </motion.div>
                              )}
                            </motion.div>
                          ) : (
                            <motion.div
                              className={`absolute ${
                                isScrolled ? "bottom-8" : "bottom-10"
                              } flex items-center gap-2 transition-all duration-500`}
                            >
                              <button
                                id="start-button"
                                disabled={remainingDuration <= 0}
                                onClick={
                                  currentUser
                                    ? startSession
                                    : () => {
                                        const currentPath = router.asPath;
                                        router.replace({
                                          pathname: "/login",
                                          query: {
                                            url: encodeURIComponent(
                                              currentPath
                                            ),
                                          },
                                        });
                                      }
                                }
                                className={`opacity-100 rounded-full py-10 px-16 flex gap-2 items-center transition-all duration-500 ease-in-out bg-white_to_white text-purple50_to_purple50 text-b2 font-medium cursor-pointer`}
                              >
                                <Image
                                  src="/images/play.svg"
                                  alt="play"
                                  width={24}
                                  height={24}
                                />
                                Start Session
                              </button>
                            </motion.div>
                          )}
                        </>
                      )}

                      {isLoadingSession && (
                        <>
                          {remainingDuration <= 120 &&
                          remainingDuration >= 0 ? (
                            <motion.div
                              onClick={(e) => e.stopPropagation()}
                              className={`rounded-full absolute ${
                                isScrolled
                                  ? "bottom-8 -translate-x-[5px]"
                                  : "bottom-10 -translate-x-[5px]"
                              } z-50`}
                              style={{
                                height: "45px",
                                width: "124px",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <motion.div className="relative -bottom-12 -right-70">
                                <LoaderFadeInOut />
                              </motion.div>
                            </motion.div>
                          ) : (
                            <motion.div
                              onClick={(e) => e.stopPropagation()}
                              className={`rounded-full absolute ${
                                isScrolled ? "bottom-8" : "bottom-10"
                              } z-50`}
                              style={{
                                height: "45px",
                                width: "144px",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <motion.div className="relative -bottom-12 -right-70">
                                <LoaderFadeInOut />
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {!isScrolled && (
                <>
                  {showLimitDesc &&
                    Limit(
                      `absolute bottom-88 w-[95vw]`,
                      "Kuota GAIA Hampir Habis!",
                      <p className="font-light text-b2 text-black_to_black">
                        Agar diskusi interaktif dengan AI dapat tetap berjalan
                        tanpa hambatan, ajukan peningkatan limit sekarang!
                      </p>,
                      setShowLimitDesc,
                      isIncreaseLimit,
                      isRequestSent,
                      setIsRequestSent,
                      limitChecked,
                      setLimitChecked,
                      showToast,
                      isScrolled,
                      handleRequestQuota,
                      currentUser,
                      dataAvatar,
                      maxDuration
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
