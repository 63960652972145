import { useEffect } from "react";

export const usePreventScroll = () => {
  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport && window.visualViewport.height) {
        document.body.style.height = `${window.visualViewport.height}px`;
      }
    };

    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, []);
};
