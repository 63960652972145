import Button from "components/Button";
import IconClose from "components/Icon/IconClose";
import { trackEventGA, trackEventGTM } from "lib/amplitude";
import Image from "next/image";

export const Limit = (
  customClass: string,
  title: string,
  desc: any,
  setShowLimitDesc: any,
  isIncreaseLimit: boolean,
  isRequestSent: boolean,
  setIsRequestSent: any,
  limitChecked: boolean,
  setLimitChecked: any,
  showToast: any,
  isScrolled?: boolean,
  handleRequestQuota?: any,
  currentUser?: any,
  dataAvatar?: any,
  maxDuration?: any
) => {
  return (
    <div
      className={`${customClass} z-20 p-16 rounded-16`}
      style={{
        backgroundColor: "#FFFFFFE5",
        backdropFilter: "blur(8px)",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {!isRequestSent && !isScrolled && (
        <>
          <div className="flex items-center justify-between">
            <p className="font-bold text-b1 text-black_to_black">{title}</p>

            <button
              onClick={() => {
                trackEventGTM({
                  event: "gaia_quota_close_a",
                  property: {
                    user_id: currentUser?.id ?? "",
                    is_logged_in: currentUser ? true : false,
                    session_id: dataAvatar?.session_id,
                    screen_state: isScrolled ? "minimize" : "maximize",
                  },
                });

                trackEventGA({
                  event: "gaia_quota_close_a",
                  property: {
                    user_id: currentUser?.id ?? "",
                    is_logged_in: currentUser ? true : false,
                    session_id: dataAvatar?.session_id,
                    screen_state: isScrolled ? "minimize" : "maximize",
                  },
                });

                setShowLimitDesc(false);
              }}
              className="cursor-pointer"
            >
              <IconClose size={20} color="#000" />
            </button>
          </div>
          <div className="mt-12 mb-12">{desc}</div>

          {!isScrolled && !isRequestSent && (
            <>
              <div className="h-[1px] w-full bg-neutral30_to_neutral30 mb-12" />
              <label
                className="mb-12 flex items-start gap-8 cursor-pointer"
                htmlFor="limit"
              >
                <input
                  type="checkbox"
                  checked={limitChecked}
                  onChange={(e) => {
                    e.stopPropagation();
                    setLimitChecked(e.target.checked);
                  }}
                  className="mt-4"
                  id="limit"
                />
                <p className="font-light text-b2 text-black_to_black">
                  Saya setuju untuk tingkatkan limit
                  {/* <span className="font-bold">GAIA</span> */}
                </p>
              </label>
            </>
          )}

          <div className="flex gap-4 items-center mb-12">
            <Button
              onClick={() => {
                trackEventGTM({
                  event: "gaia_quota_close_a",
                  property: {
                    user_id: currentUser?.id ?? "",
                    is_logged_in: currentUser ? true : false,
                    session_id: dataAvatar?.session_id,
                    screen_state: isScrolled ? "minimize" : "maximize",
                  },
                });

                trackEventGA({
                  event: "gaia_quota_close_a",
                  property: {
                    user_id: currentUser?.id ?? "",
                    is_logged_in: currentUser ? true : false,
                    session_id: dataAvatar?.session_id,
                    screen_state: isScrolled ? "minimize" : "maximize",
                  },
                });

                setShowLimitDesc(false);
              }}
              variant="transparant"
              className="!w-[100%]"
            >
              Batal
            </Button>
            <Button
              state={limitChecked ? "active" : "disabled"}
              variant="black"
              className="!w-[100%]"
              onClick={handleRequestQuota}
            >
              Ajukan Sekarang
            </Button>
          </div>
        </>
      )}

      {isRequestSent && (
        // <div className="w-full bg-[#E4F8E9] rounded-6 flex gap-8 justify-center !px-4 items-start">
        //   <Image
        //     src={"/images/check.svg"}
        //     alt="gaia"
        //     width={16}
        //     height={16}
        //     className="py-10 !min-w-16 !min-h-16"
        //   />
        //   <p className="text-[#318A4E] py-10 text-c1 font-medium">
        //     Terima kasih! Request Anda sudah kami terima dan akan diproses.
        //     Sambil menunggu, Anda bisa belajar melalui kursus atau menunggu
        //     waktu GAIA ter-reset.
        //   </p>
        // </div>
        <>
          <div className="flex items-center justify-between">
            <p className="font-bold text-b1 text-[#318A4E]">
              Pengajuan Terkirim
            </p>

            <button
              onClick={() => setShowLimitDesc(false)}
              className="cursor-pointer"
            >
              <IconClose size={20} color="#000" />
            </button>
          </div>
          <div className="mt-12 mb-12">
            <p className="font-light text-b2 text-black_to_black">
              Kuota Interaktif GAIA sebesar{" "}
              <span className="font-medium">
                {Math.floor(maxDuration / 60)} menit
              </span>{" "}
              akan ditambahkan pada{" "}
              <span className="font-medium">30 Januari 2025</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};
