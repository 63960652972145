export const INTRO = [
  {
    knowledgeId: "17be033eeeee42b48ff1a21e2e086098",
    introTextID:
      "Halo, saya GAIA, goKampus AI Advisor. Ada yang bisa saya bantu?",
    introTextEN: "Hi, I'm GAIA, goKampus AI Advisor. Any questions?",
  },
  {
    knowledgeId: "ea46ba7fdf0d4e60a66ebab4d48914d2",
    introTextID:
      "Halo, Saya Nathan, Founder dan CEO goKampus, dan ini adalah versi AI saya. Apakah ada yang bisa saya bantu?",
    introTextEN:
      "Hi, I'm Nathan, founder and CEO of goKampus, and this is my AI version. Any questions?",
  },
  {
    knowledgeId: "368b760d8f2f446ba501bd05791606cb",
    introTextID:
      "Halo, saya di sini untuk membantu Anda mempelajari lebih lanjut tentang UKRIDA. Bagaimana saya dapat membantu Anda hari ini?",
    introTextEN:
      "Hello, I’m here to help you learn more about UKRIDA’s. How can I assist you today?",
  },
  {
    knowledgeId: "d6cf967a662745c19810be1e8cc41a89",
    introTextID:
      "Hai, saya Nathan, Pendiri dan CEO goKampus, dan ini adalah versi AI saya. Apakah Anda memiliki pertanyaan tentang perbankan?",
    introTextEN:
      "Hi, I’m Nathan, Founder and CEO of goKampus, and this is my AI version. Do you have any questions about banking?",
  },
  {
    knowledgeId: "9f8d983b8dbd4e538dac102a6db04da0",
    introTextID:
      "Halo! Saya Nathan, pendiri dan CEO goKampus, dan dalam bentuk AI ini, saya berdedikasi untuk memberdayakan perjalanan profesional Anda dengan memberikan wawasan berharga, saran yang dapat ditindaklanjuti, dan sumber daya yang dirancang khusus untuk membantu Anda berkembang dalam karier korporat Anda.",
    introTextEN:
      "Hello! I am Nathan, the founder and CEO of goKampus, and in this AI form, I'm dedicated to empowering your professional journey by providing valuable insights, actionable advice, and resources tailored to help you thrive in your corporate career.",
  },
  {
    knowledgeId: "54a3b0cdbb3046c2bc3baa1cb1e8ea37",
    introTextID:
      "Hai, saya Nathan, Pendiri dan CEO goKampus, dan ini adalah versi AI saya. Hari ini saya di sini untuk membantu Anda membahas masalah perbankan, khususnya dengan CIMB Niaga. Apakah Anda punya pertanyaan?",
    introTextEN:
      "Hi, I’m Nathan, Founder and CEO of goKampus, and this is my AI version. Today I’m here to help you discuss banking matters, specifically with CIMB Niaga. Do you have any questions?",
  },
  {
    knowledgeId: "7cc1557de63249ffb5e21a7d807eaaa3",
    introTextID:
      "Halo! Saya Nathan, pendiri dan CEO goKampus, dan dalam bentuk AI ini, saya berdedikasi untuk memberdayakan perjalanan profesional Anda dengan memberikan wawasan berharga, saran yang dapat ditindaklanjuti, dan sumber daya yang dirancang khusus untuk membantu Anda berkembang dalam karier korporat Anda.",
    introTextEN:
      "Hello! I am Nathan, the founder and CEO of goKampus, and in this AI form, I'm dedicated to empowering your professional journey by providing valuable insights, actionable advice, and resources tailored to help you thrive in your corporate career.",
  },
  {
    knowledgeId: "57c6f51281f5498c9656d6a7a39714ed",
    introTextID:
      "Hai, saya Nathan, pendiri dan CEO goKampus, dan ini versi AI saya. Ada pertanyaan?",
    introTextEN:
      "Hi, I'm Nathan, founder and CEO of goKampus, and this is my AI version. Any questions?",
  },
  {
    knowledgeId: "9d4afddd1d2841a29bfc249c0cbc9995",
    introTextID:
      "Hai, saya Nathan, pendiri dan CEO goKampus, dan ini versi AI saya. Ada pertanyaan?",
    introTextEN:
      "Hi, I'm Nathan, founder and CEO of goKampus, and this is my AI version. Any questions?",
  },
  {
    knowledgeId: "8494c883566645b0825c617b87586c1b",
    introTextID:
      "Hai, saya Nathan, Pendiri dan CEO goKampus, dan ini adalah versi AI saya. Hari ini saya di sini untuk membantu Anda membahas masalah perbankan, khususnya dengan CIMB Niaga. Apakah Anda punya pertanyaan?",
    introTextEN:
      "Hi, I’m Nathan, Founder and CEO of goKampus, and this is my AI version. Today I’m here to help you discuss banking matters, specifically with CIMB Niaga. Do you have any questions?",
  },
];
