import { motion } from "framer-motion";

export const AudioVisualizer = ({ volumes, customClass }) => {
  return (
    <motion.div className={customClass}>
      <motion.div className="flex space-x-1 gap-4">
        {volumes.map((volume, index) => (
          <motion.div
            key={index}
            className="w-4 max-h-8 bg-black_to_black rounded-md"
            style={{ height: `${Math.max(volume, 10)}px` }}
            animate={{ scaleY: 1 + volume / 50 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 15,
            }}
          />
        ))}
      </motion.div>
    </motion.div>
  );
};
