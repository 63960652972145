import Button from "components/Button";
import IconClose from "components/Icon/IconClose";
import { motion } from "framer-motion";
import { formatDate } from "lib/formatDate";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { useAvatarStore } from "./avatarStore";
import { CONST } from "lib/const";
import { useIsDesktop } from "lib/device";

export const SidebarDesktop = ({
  isQuizStarted,
  setIsQuizStarted,
  saveChat,
  filterChatWithQuestion,
  textValue,
  setTextValue,
  handleSpeakText,
  showSidebar,
  toggleSidebar,
  chat,
  lastDisplayedDate,
  currentUser,
  handleCopyText,
  copySuccess,
  chatEndRef,
  showSummarySidebar,
  toggleSummarySidebar,
  activeTab,
  setActiveTab,
  stream,
  historySummary,
  summaryRecap,
  summaryEndRef,
  showQuizSidebar,
  toggleQuizSidebar,
  customClassTranscript,
  customClassSummary,
}) => {
  const isDesktop = useIsDesktop();
  const { isScrolled } = useAvatarStore();

  // Batch Loading
  const [visibleChats, setVisibleChats] = useState(chat?.chat.slice(-20) || []);
  const containerChatRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chat?.chat) {
      setVisibleChats(chat.chat.slice(-20));
    }
  }, [chat?.chat]);

  const loadMoreVisibleChats = () => {
    if (!chat?.chat || visibleChats.length >= chat.chat.length) return;

    const currentLength = visibleChats.length;
    const newVisibleChats = chat.chat.slice(
      Math.max(0, chat.chat.length - currentLength - 20),
      chat.chat.length - currentLength
    );

    setVisibleChats((prevVisibleChats) => [
      ...newVisibleChats,
      ...prevVisibleChats,
    ]);
  };

  useEffect(() => {
    setTimeout(() => {
      if (containerChatRef.current) {
        containerChatRef.current.scrollTop =
          containerChatRef.current.scrollHeight;
      }
    }, 0);
  }, [chat.chat, isScrolled, showSidebar]);

  const handleChatScroll = () => {
    if (containerChatRef.current?.scrollTop === 0) {
      loadMoreVisibleChats();
    }
  };

  const [visibleSummaries, setVisibleSummaries] = useState(
    historySummary?.summary.slice(-20) || []
  );
  const containerSummaryRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (historySummary?.summary) {
      setVisibleSummaries(historySummary.summary.slice(-20));
    }
  }, [historySummary?.summary]);

  const loadMoreVisibleSummaries = () => {
    if (
      !historySummary?.summary ||
      visibleSummaries.length >= historySummary.summary.length
    )
      return;

    const currentLength = visibleSummaries.length;
    const newVisibleSummaries = historySummary.summary.slice(
      Math.max(0, historySummary.summary.length - currentLength - 20),
      historySummary.summary.length - currentLength
    );

    setVisibleSummaries((prevVisibleSummaries) => [
      ...newVisibleSummaries,
      ...prevVisibleSummaries,
    ]);
  };

  const handleSummaryScroll = () => {
    if (containerSummaryRef.current?.scrollTop === 0) {
      loadMoreVisibleSummaries();
    }
  };

  const [visibleRecaps, setVisibleRecaps] = useState(
    summaryRecap?.summary.slice(-20) || []
  );
  useEffect(() => {
    if (summaryRecap?.summary) {
      setVisibleRecaps(summaryRecap.summary.slice(-20));
    }
  }, [summaryRecap?.summary]);

  const loadMoreVisibleRecaps = () => {
    if (
      !summaryRecap?.summary ||
      visibleRecaps.length >= summaryRecap.summary.length
    )
      return;

    const currentLength = visibleRecaps.length;
    const newVisibleRecaps = summaryRecap.summary.slice(
      Math.max(0, summaryRecap.summary.length - currentLength - 20),
      summaryRecap.summary.length - currentLength
    );

    setVisibleRecaps((prevVisibleRecaps) => [
      ...newVisibleRecaps,
      ...prevVisibleRecaps,
    ]);
  };

  useEffect(() => {
    setTimeout(() => {
      if (containerSummaryRef.current) {
        containerSummaryRef.current.scrollTop =
          containerSummaryRef.current.scrollHeight;
      }
    }, 0);
  }, [
    historySummary.summary,
    summaryRecap.summary,
    isScrolled,
    showSummarySidebar,
    activeTab,
  ]);

  const handleRecapScroll = () => {
    if (containerSummaryRef.current?.scrollTop === 0) {
      loadMoreVisibleRecaps();
    }
  };
  // End Batch Loading

  const quizSidebarRef = useRef(null);
  const sidebarRef = useRef(null);
  const summarySidebarRef = useRef(null);

  useEffect(() => {
    let gsap;
    import("gsap").then(({ default: importedGsap }) => {
      gsap = importedGsap;

      const sidebar = quizSidebarRef.current;

      if (showQuizSidebar) {
        gsap.to(sidebar, {
          x: 0,
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          pointerEvents: "auto",
        });
      } else {
        gsap.to(sidebar, {
          x: "-35%",
          opacity: 0,
          duration: 0.5,
          ease: "power2.in",
          pointerEvents: "none",
        });
      }
    });

    return () => {
      if (gsap) {
        gsap.killTweensOf(quizSidebarRef.current);
      }
    };
  }, [showQuizSidebar]);

  useEffect(() => {
    let gsap;
    import("gsap").then(({ default: importedGsap }) => {
      gsap = importedGsap;

      const sidebar = sidebarRef.current;

      if (showSidebar) {
        gsap.to(sidebar, {
          x: 0,
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          pointerEvents: "auto",
        });
      } else {
        gsap.to(sidebar, {
          x: "-35%",
          opacity: 0,
          duration: 0.5,
          ease: "power2.in",
          pointerEvents: "none",
        });
      }
    });

    return () => {
      if (gsap) {
        gsap.killTweensOf(sidebarRef.current);
      }
    };
  }, [showSidebar]);

  useEffect(() => {
    let gsap;
    import("gsap").then(({ default: importedGsap }) => {
      gsap = importedGsap;

      const sidebar = summarySidebarRef.current;

      if (showSummarySidebar) {
        gsap.to(sidebar, {
          x: 0,
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
          pointerEvents: "auto",
        });
      } else {
        gsap.to(sidebar, {
          x: "-35%",
          opacity: 0,
          duration: 0.5,
          ease: "power2.in",
          pointerEvents: "none",
        });
      }
    });

    return () => {
      if (gsap) {
        gsap.killTweensOf(summarySidebarRef.current);
      }
    };
  }, [showSummarySidebar]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [step, setStep] = useState(1);

  const lastIndex = filterChatWithQuestion.length - 1;
  const latestQuestion = filterChatWithQuestion[lastIndex]?.avatar;
  const lastItem = filterChatWithQuestion[lastIndex];
  const [isSubmitted, setIsSubmitted] = useState(false);

  const processedQuestion = (latestQuestion) => {
    const lines = latestQuestion
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    const questionLineIndex = lines.findIndex((line) =>
      /pertanyaan\s*\d*:|^\d+[.)]/i.test(line)
    );

    if (questionLineIndex === -1) {
      return null;
    }

    let questionLine = lines[questionLineIndex];

    const questionNextLine =
      lines[questionLineIndex + 1] &&
      !/^[A-Da-d][.)]?\s+/i.test(lines[questionLineIndex + 1])
        ? lines[questionLineIndex + 1]
        : "";
    questionLine += questionNextLine ? ` ${questionNextLine}` : "";

    const questionMatch = questionLine.match(/(Pertanyaan)\s*(\d+):?\s*(.*)/i);
    const questionLabel = questionMatch?.[1] || "Pertanyaan";
    const questionNumber = questionMatch?.[2] || "";
    const questionText = questionMatch?.[3] || questionLine;

    const optionRegex = /^[A-Da-d][.)]?\s+/;

    const options = lines
      .slice(questionLineIndex + 1)
      .filter((line, index, arr) => {
        const isOption = optionRegex.test(line);
        const isFollowingEmptyLine =
          index > 0 && arr[index - 1].length === 0 && isOption;
        return isOption || isFollowingEmptyLine;
      })
      .map((line) => line.replace(optionRegex, (match) => match.toUpperCase()));

    return {
      questionLabel,
      questionNumber,
      question: questionText.trim() || "Pertanyaan tidak ditemukan",
      options,
    };
  };

  const currentQuestion =
    typeof latestQuestion === "string"
      ? processedQuestion(latestQuestion)
      : null;

  const filterChatWithAnswer = saveChat.chat.filter((item) => {
    const avatarText = item.avatar?.toLowerCase();

    return avatarText.startsWith("jawaban anda");
  });

  const lastIndexAnswer = filterChatWithAnswer.length - 1;
  const latestAnswer = filterChatWithAnswer[lastIndexAnswer]?.avatar
    ?.toLowerCase()
    ?.trim();

  const containsCorrectAnswer =
    latestAnswer?.includes("benar") || latestAnswer?.includes("betul");

  const [showWrongOrCorrect, setShowWrongOrCorrect] = useState(false);

  const fetchHandleSpeakText = async () => {
    await handleSpeakText();
  };

  useEffect(() => {
    setSelectedOption(null);
    setIsSubmitted(false);
    setShowWrongOrCorrect(false);
  }, [filterChatWithQuestion.length]);

  useEffect(() => {
    if (selectedOption !== null) {
      fetchHandleSpeakText();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isSubmitted) {
      fetchHandleSpeakText();
    }
  }, [isSubmitted]);

  useEffect(() => {
    setShowWrongOrCorrect(true);
  }, [filterChatWithAnswer.length]);

  const filterChatWithScore = saveChat.chat.filter((item) => {
    const avatarText = item.avatar?.toLowerCase();

    return (
      avatarText.includes("skor") ||
      avatarText.includes("grade") ||
      avatarText.includes("passing")
    );
  });

  const lastIndexScore = filterChatWithScore.length - 1;
  const latestScore = filterChatWithScore[lastIndexScore]?.avatar;
  const isScoreFailed =
    latestScore?.includes("gagal") ||
    latestScore?.includes("tidak lolos") ||
    latestScore?.includes("tidak lulus") ||
    latestScore?.includes("belum lolos") ||
    latestScore?.includes("belum lulus") ||
    latestScore?.includes("tidak berhasil") ||
    latestScore?.includes("belum berhasil");

  const API_URL = "https://api.openai.com/v1/chat/completions";

  const improveScore = async (sentences: any) => {
    const promptText = `Ganti skor total atau hasil akhir menjadi dalam bentuk angka seperti 100/100 : "${JSON.stringify(
      sentences
    )}"`;

    const messages = [{ role: "user", content: promptText }];

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${CONST.OPENAI_APIKEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: messages,
          stream: false,
        }),
      });

      const responseJson = await response.json();

      return responseJson.choices[0].message.content;
    } catch (error) {
      console.error(error);
    }
  };

  const [finalScore, setFinalScore] = useState("");

  const extractScoreFromPercentage = (text: string) => {
    const regex = /(\d+(?:\.\d+)?)%/;
    const match = text.match(regex);
    return match ? match[1] : "";
  };

  const extractScore = (text: string) => {
    const regex = /(\d+\/\d+)/;
    const match = text.match(regex);
    return match ? match[1] : "";
  };

  useEffect(() => {
    if (filterChatWithScore.length > 0) {
      improveScore(latestScore).then((response) => {
        console.log("response : ", response);
        setFinalScore(extractScore(response));
      });
    }
  }, [filterChatWithScore.length]);

  const [recapInfo, setRecapInfo] = useState(false);

  // useEffect(() => {
  //   if (showQuizSidebar || showSidebar || showSummarySidebar) {
  //     console.log(typeof latestQuestion === "string");
  //     console.log("latestQuestion : ", latestQuestion);
  //     console.log("processedQuestion : ", currentQuestion);
  //     console.log("filterChatWithAnswer : ", filterChatWithAnswer);
  //     console.log("latestAnswer : ", latestAnswer);
  //     console.log("containsCorrectAnswer : ", containsCorrectAnswer);
  //     console.log("filterChatWithScore : ", filterChatWithScore);
  //     console.log("latestScore : ", latestScore);
  //     console.log("isScoreFailed : ", isScoreFailed);
  //   }
  // }, [showQuizSidebar, showSidebar, showSummarySidebar]);

  return (
    <div className="overflow-x-hidden">
      {filterChatWithQuestion.length > 0 && (
        <div
          // ref={quizSidebarRef}
          onClick={(e) => e.stopPropagation()}
          className={`${customClassTranscript} ${
            showQuizSidebar
              ? "ease-in opacity-100 visible pointer-events-auto"
              : "ease-out opacity-0 invisible pointer-events-none"
          }`}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.44)",
            backdropFilter: "blur(8px)",
            // transform: "translateX(100%)",
          }}
        >
          <h4
            className="absolute top-12 left-12 text-white_to_white text-h4 font-medium"
            onClick={(e) => e.stopPropagation()}
          >
            Quiz{" "}
            {step == 1 ? (
              <span className="font-light opacity-50">
                | {currentQuestion?.questionNumber}/3 Pertanyaan
              </span>
            ) : (
              <span className="font-light opacity-50">| 3/3 Pertanyaan</span>
            )}
          </h4>

          <button
            id="close-button"
            className="absolute top-12 right-12 hover:cursor-pointer"
            onClick={(e) => {
              toggleQuizSidebar(e);
            }}
          >
            <IconClose size={20} color="#D3D2D7" />
          </button>

          {/* <button
          id="close-button"
          className="absolute top-12 right-12 hover:cursor-pointer"
          onClick={toggleQuizSidebar}
        >
          <Image
            src={"/images/collapse.svg"}
            alt="collapse"
            width={20}
            height={20}
          />
        </button> */}

          <motion.div className="overflow-y-auto hide-scrollbar h-full flex flex-col justify-between m-auto">
            {step === 1 ? (
              <>
                <div className="my-auto">
                  <p
                    className="text-white_to_white font-light text-b1"
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion?.question
                        .replace(/^\*\*(.*)/, "$1")
                        .replace(/#+(.*?)#/g, "<b>$1</b>")
                        .replace(/\n/g, "<br>"),
                    }}
                  />
                  <div className="flex flex-col gap-16 mt-16">
                    {currentQuestion?.options.map((option, index) => (
                      <button
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedOption(index);
                          setTextValue(option);
                        }}
                        className={`w-full text-left bg-[#00000066] rounded-[100px] py-8 px-12 text-white_to_white !text-b1 !font-light ${
                          selectedOption === index
                            ? "!border border-white_to_white"
                            : "!border-0"
                        }`}
                      >
                        {option}
                      </button>
                    ))}

                    {showWrongOrCorrect && (
                      <>
                        {containsCorrectAnswer && isSubmitted && (
                          <div className="w-full text-left rounded-8 bg-[#FFFFFF14] py-8 px-12 !border-0 flex gap-8">
                            <Image
                              src={"/images/correct.svg"}
                              alt="correct"
                              width={18}
                              height={18}
                            />
                            <p className="text-white_to_white text-b2 font-light">
                              Jawaban Benar
                            </p>
                          </div>
                        )}

                        {!containsCorrectAnswer && isSubmitted && (
                          <div className="w-full text-left rounded-8 bg-[#FFFFFF14] py-8 px-12 !border-0 flex gap-8">
                            <Image
                              src={"/images/wrong.svg"}
                              alt="wrong"
                              width={18}
                              height={18}
                            />
                            <p className="text-white_to_white text-b2 font-light">
                              Jawaban Salah :{" "}
                              <span className="font-medium">
                                {typeof currentQuestion?.options[
                                  selectedOption
                                ] === "string"
                                  ? (currentQuestion?.options[
                                      selectedOption
                                    ])[0]
                                  : ""}
                              </span>
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {(currentQuestion?.questionNumber != "3" ||
                  (currentQuestion?.questionNumber == "3" && !isSubmitted)) && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setTextValue("Ya");
                      setIsSubmitted(true);
                    }}
                    state={selectedOption !== null ? "active" : "disabled"}
                    variant="gray_transparant"
                    isFull
                    className="rounded-[100px]"
                  >
                    Submit
                  </Button>
                )}

                {currentQuestion?.questionNumber == "3" && isSubmitted && (
                  <Button
                    onClick={() => {
                      setIsSubmitted(false);
                      setStep(2);
                    }}
                    variant="gray_transparant"
                    isFull
                    className="rounded-[100px]"
                  >
                    Selanjutnya
                  </Button>
                )}
              </>
            ) : (
              <>
                <div className="my-auto">
                  {!isScoreFailed ? (
                    <div className="mx-40 rounded-8 bg-[#FFFFFF14] p-12 !border-0 justify-center items-center flex flex-col gap-8">
                      <Image
                        src={"/images/correct.svg"}
                        alt="correct"
                        width={30}
                        height={30}
                      />
                      <p className="text-white_to_white text-b1 font-medium">
                        Selamat, Kamu Berhasil lolos quiz
                      </p>
                      <p className="text-white_to_white text-b2 font-light">
                        Penilaian: {finalScore}
                      </p>
                    </div>
                  ) : (
                    <div className="mx-40 rounded-8 bg-[#FFFFFF14] p-12 !border-0 justify-center items-center flex flex-col gap-8">
                      <Image
                        src={"/images/wrong.svg"}
                        alt="wrong"
                        width={30}
                        height={30}
                      />
                      <p className="text-white_to_white text-b1 font-medium">
                        Maaf, Kamu belum berhasil lolos quiz
                      </p>
                      <p className="text-white_to_white text-b2 font-light">
                        Penilaian: {finalScore}
                      </p>
                    </div>
                  )}
                </div>

                {!isScoreFailed ? (
                  <Button
                    variant="gray_transparant"
                    isFull
                    className="rounded-[100px]"
                    onClick={(e) => {
                      toggleQuizSidebar(e);
                      setIsQuizStarted(false);
                      setStep(1);
                    }}
                  >
                    Tutup Quiz
                  </Button>
                ) : (
                  <Button
                    variant="gray_transparant"
                    isFull
                    className="rounded-[100px]"
                    onClick={(e) => {
                      toggleQuizSidebar(e);
                      setTextValue("mulai quiz");
                      setStep(1);
                    }}
                  >
                    Coba Lagi
                  </Button>
                )}
              </>
            )}
          </motion.div>
        </div>
      )}

      <div
        // ref={sidebarRef}
        onClick={(e) => e.stopPropagation()}
        className={`${customClassTranscript} ${
          showSidebar
            ? "ease-in opacity-100 visible pointer-events-auto"
            : "ease-out opacity-0 invisible pointer-events-none"
        }`}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.44)",
          backdropFilter: "blur(8px)",
          // transform: "translateX(100%)",
        }}
      >
        <h4
          className="absolute top-12 left-12 text-white_to_white text-h4 font-medium"
          onClick={(e) => e.stopPropagation()}
        >
          Transkrip Percakapan
        </h4>

        {/* <button
          id="close-button"
          className="absolute top-12 right-12 hover:cursor-pointer"
          onClick={toggleSidebar}
        >
          <Image
            src={"/images/collapse.svg"}
            alt="collapse"
            width={20}
            height={20}
          />
        </button> */}

        <button
          id="close-button"
          className="absolute top-12 right-12 hover:cursor-pointer"
          onClick={toggleSidebar}
        >
          <IconClose size={20} color="#D3D2D7" />
        </button>

        <motion.div
          ref={containerChatRef}
          onScroll={handleChatScroll}
          className="overflow-y-auto hide-scrollbar h-full"
        >
          {chat && (
            <>
              {visibleChats.map((item, index) => {
                const currentDate = new Date(item?.date).toDateString();
                const shouldDisplayDate = currentDate !== lastDisplayedDate;

                if (shouldDisplayDate) {
                  lastDisplayedDate = currentDate;
                }

                return (
                  <div key={index} data-index={index} className="chat-item">
                    {shouldDisplayDate && (
                      <motion.div className="flex items-center my-4 opacity-50">
                        <motion.div className="flex-grow border-t border-[#D9D9D9]"></motion.div>
                        <span className="mx-4 text-b2 font-light text-white_to_white">
                          {formatDate(item?.date as string)}
                        </span>
                        <motion.div className="flex-grow border-t border-[#D9D9D9]"></motion.div>
                      </motion.div>
                    )}

                    <motion.div className="mb-12">
                      {item?.user &&
                        item?.user != "" &&
                        item?.user != "-" &&
                        item?.user.toLowerCase() != "mulai quiz" && (
                          <motion.div className="flex justify-end flex-shrink-0 gap-8 mt-12 mb-12">
                            <motion.div
                              className="p-20 rounded-xl"
                              style={{
                                backgroundColor: "rgba(0, 0, 0, 0.44)",
                              }}
                            >
                              <p
                                className="text-white_to_white font-light text-sm"
                                dangerouslySetInnerHTML={{
                                  __html: item?.user
                                    .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                                    .replace(/#+(.*?)#/g, "<b>$1</b>")
                                    .replace(/\n/g, "<br>"),
                                }}
                              />
                            </motion.div>
                            <motion.div className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center bg-purple-10 text-purple-60">
                              {currentUser?.name[0].toUpperCase()}
                            </motion.div>
                          </motion.div>
                        )}

                      {item?.avatar && item?.avatar != "" && (
                        <motion.div>
                          <motion.div className="flex flex-shrink-0 gap-8">
                            <motion.div
                              className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center"
                              style={{
                                background:
                                  "linear-gradient(270deg, rgba(85, 31, 193, 0.44) 0%, rgba(243, 122, 65, 0.44) 100%)",
                              }}
                            >
                              <Image
                                src={"/images/star-gaia.svg"}
                                alt="gaia"
                                width={24}
                                height={24}
                                className="rounded-full"
                              />
                            </motion.div>
                            <p
                              className="text-white_to_white font-light text-sm"
                              dangerouslySetInnerHTML={{
                                __html: item?.avatar
                                  .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                                  .replace(/#+(.*?)#/g, "<b>$1</b>")
                                  .replace(/\n/g, "<br>"),
                              }}
                            />
                          </motion.div>
                          <motion.div className="cursor-pointer ml-32 mt-8 flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center">
                            <Image
                              onClick={(e) =>
                                handleCopyText(e, item?.avatar, index)
                              }
                              src={
                                copySuccess[index]
                                  ? "/images/copied.svg"
                                  : "/images/copy.svg"
                              }
                              alt="gaia"
                              width={24}
                              height={24}
                              className="rounded-full"
                            />
                          </motion.div>
                        </motion.div>
                      )}
                    </motion.div>
                  </div>
                );
              })}
              {/* <motion.div ref={chatEndRef} /> */}
            </>
          )}
        </motion.div>
      </div>

      <div
        // ref={summarySidebarRef}
        onClick={(e) => e.stopPropagation()}
        className={`${customClassSummary} ${
          showSummarySidebar
            ? "ease-in opacity-100 visible pointer-events-auto"
            : "ease-out opacity-0 invisible pointer-events-none"
        }`}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.44)",
          backdropFilter: "blur(8px)",
          // transform: "translateX(100%)",
        }}
      >
        {/* Header Section */}
        <div className="absolute top-12 left-12 flex items-center gap-12">
          <h4 className="text-white_to_white text-h4 font-medium">Summary</h4>
        </div>

        {/* Close Button */}
        <button
          id="close-button"
          className="absolute top-12 right-12 hover:cursor-pointer"
          onClick={toggleSummarySidebar}
        >
          <IconClose size={20} color="#D3D2D7" />
        </button>

        {/* <button
          id="close-button"
          className="absolute top-12 right-12 hover:cursor-pointer"
          onClick={toggleSummarySidebar}
        >
          <Image
            src={"/images/collapse.svg"}
            alt="collapse"
            width={20}
            height={20}
          />
        </button> */}

        {/* Content Section */}
        <motion.div
          ref={containerSummaryRef}
          onScroll={handleRecapScroll}
          className={`overflow-y-auto hide-scrollbar h-full pt-32`}
        >
          {visibleRecaps.map((item, index) => {
            const currentDate = new Date(item?.date).toDateString();
            const shouldDisplayDate = currentDate !== lastDisplayedDate;

            if (shouldDisplayDate) {
              lastDisplayedDate = currentDate;
            }

            return (
              item?.title &&
              item?.content && (
                <motion.div
                  key={index}
                  data-index={index}
                  className="recap-item my-12"
                >
                  {shouldDisplayDate && (
                    <motion.div className="flex items-center my-4 opacity-50">
                      <motion.div className="flex-grow border-t border-[#D9D9D9]"></motion.div>
                      <span className="mx-4 text-b2 font-light text-white_to_white">
                        {formatDate(item?.date as string)}
                      </span>
                      <motion.div className="flex-grow border-t border-[#D9D9D9]"></motion.div>
                    </motion.div>
                  )}

                  <motion.div className="flex flex-shrink-0 gap-8">
                    <motion.div
                      className="cursor-pointer flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center"
                      style={{
                        background:
                          "linear-gradient(270deg, rgba(85, 31, 193, 0.44) 0%, rgba(243, 122, 65, 0.44) 100%)",
                      }}
                    >
                      <Image
                        src={"/images/star-gaia.svg"}
                        alt="gaia"
                        width={24}
                        height={24}
                        className="rounded-full"
                      />
                    </motion.div>
                    <motion.div>
                      <p className="text-white_to_white font-medium text-b1">
                        {item?.title}
                      </p>
                      <p
                        className="text-white_to_white font-light text-sm"
                        dangerouslySetInnerHTML={{
                          __html:
                            typeof item?.content === "string"
                              ? item?.content
                                  .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                                  .replace(/#+(.*?)#/g, "<b>$1</b>")
                                  .replace(/\n/g, "<br>")
                              : item?.content,
                        }}
                      />
                    </motion.div>
                  </motion.div>
                  <motion.div className="cursor-pointer ml-32 mt-8 flex-shrink-0 rounded-full w-32 h-32 p-5 flex items-center justify-center">
                    <Image
                      onClick={(e) => handleCopyText(e, item?.content, index)}
                      src={
                        copySuccess[index]
                          ? "/images/copied.svg"
                          : "/images/copy.svg"
                      }
                      alt="copy"
                      width={24}
                      height={24}
                      className="rounded-full"
                    />
                  </motion.div>
                </motion.div>
              )
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};
