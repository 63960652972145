import IconClose from "components/Icon/IconClose";
import Image from "next/image";
import { motion } from "framer-motion";
import LoaderFadeInOut from "components/Loader/LoaderFadeInOut";
import { Limit } from "./Limit";
import { useEffect, useRef, useState } from "react";
import { useAvatarStore } from "./avatarStore";
import { formatDate } from "lib/formatDate";
import { useRouter } from "next/router";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SidebarDesktop } from "./SidebarDesktop";
import { AudioVisualizer } from "./AudioVisualizer";
import clsx from "clsx";
import { trackEventGA, trackEventGTM } from "lib/amplitude";
import { BottomNavMobile } from "./BottomNavMobile";
import { useSystemStore } from "components/PageHeader/systemStore";
import { useAuthStore } from "modules/Auth/authStore";

export const AvatarDesktop = ({
  handleInterrupt,
  dataAvatar,
  isAnyFiltered,
  isKeywordsSearched,
  isScrolled,
  handleMinimize,
  isSmaller,
  mediaStream,
  isMediaReady,
  setIsMediaReady,
  startSession,
  endSession,
  isLoadingSession,
  stream,
  toggleSidebar,
  showSidebar,
  toggleSummarySidebar,
  showSummarySidebar,
  toggleQuizSidebar,
  showQuizSidebar,
  chat,
  lastDisplayedDate,
  currentUser,
  handleCopyText,
  copySuccess,
  chatEndRef,
  historySummary,
  summaryRecap,
  summaryEndRef,
  scrollToTop,
  handleBigger,
  handleSmaller,
  volumes,
  isVoiceChatActive,
  handleCloseVoiceChat,
  handleStartVoiceChat,
  disabledMic,
  micIsLoading,
  modeIsLoading,
  remainingDuration,
  maxDuration,
  formatTime,
  showLimitDesc,
  setShowLimitDesc,
  isRequestSent,
  setIsRequestSent,
  isIncreaseLimit,
  setIsIncreaseLimit,
  limitChecked,
  setLimitChecked,
  showAllSidebar,
  setShowAllSidebar,
  handleFullScreen,
  showToast,
  activeTab,
  setActiveTab,
  handleRequestQuota,
  chatMode,
  isUserTalking,
  textValue,
  setTextValue,
  handleSpeakText,
  isLoadingRepeat,
  handleChangeChatMode,
  saveChat,
  textQuiz,
  setTextQuiz,
  handleSpeakQuiz,
}) => {
  const router = useRouter();
  const [showAdditionalContent, setShowAdditionalContent] = useState(true);
  const { isAvatarFullScreen } = useAvatarStore();

  const handleSubmit = () => {
    if (textValue.trim() === "") {
      return;
    }
    handleSpeakText();
    setTextValue("");
  };

  useEffect(() => {
    setShowAdditionalContent(false);
    const timeout = setTimeout(() => {
      setShowAdditionalContent(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [isScrolled]);

  const containerRef = useRef(null);
  const videoContainerRef = useRef(null);

  useEffect(() => {
    Promise.all([import("gsap"), import("gsap/ScrollTrigger")]).then(
      ([{ default: gsap }, { ScrollTrigger }]) => {
        gsap.registerPlugin(ScrollTrigger);

        const videoContainer = videoContainerRef.current;

        if (!stream) {
          gsap.set(videoContainer, {
            width: "0px",
            bottom: 0,
            right: 0,
            position: "fixed",
          });
        } else if (stream && isScrolled) {
          gsap.set(videoContainer, {
            width: "100%",
            bottom: 0,
            right: 0,
            position: "fixed",
          });
        } else {
          gsap.set(videoContainer, {
            width: "22vw",
            bottom: 20,
            right: 20,
            position: "fixed",
          });
        }

        if (stream) {
          const tl = gsap.timeline({
            defaults: { duration: 1, ease: "power2.inOut" },
          });

          if (isScrolled) {
            tl.to(videoContainer, {
              width: "22vw",
              bottom: 20,
              right: 20,
              position: "fixed",
            });
          } else {
            tl.to(videoContainer, {
              width: "100%",
              bottom: 0,
              right: 0,
              zIndex: 40,
              position: "fixed",
            });
          }

          tl.play();

          return () => {
            tl.kill();
          };
        }
      }
    );
  }, [isScrolled, isSmaller, stream]);

  const [isQuizStarted, setIsQuizStarted] = useState(false);

  const excludedWords = [
    "ketentuan",
    "trigger",
    "skor",
    "grade",
    "passing",
    "penilaian",
    "lolos",
    "gaia",
    "jika anda",
    "memiliki pertanyaan",
  ];

  const includeWords = [":"];

  const filterChatWithQuestion = saveChat.chat.filter((item) => {
    const avatarText = item.avatar?.toLowerCase();

    const containsExcludedWord = excludedWords.some((word) =>
      avatarText.includes(word)
    );

    const containsIncludeWord = includeWords.some((word) =>
      avatarText.includes(word)
    );

    return (
      avatarText.includes("pertanyaan") &&
      containsIncludeWord &&
      !containsExcludedWord
    );
  });

  const fetchHandleSpeakQuiz = async () => {
    await handleSpeakQuiz();
  };

  useEffect(() => {
    if (
      saveChat.chat.length % 3 === 0 &&
      saveChat.chat.length !== 0 &&
      !isQuizStarted
    ) {
      setTextQuiz(`
        Quiz Trigger Conditions:

        - Apakah pengguna telah mengajukan tiga pertanyaan pada topik diskusi yang sama terkait pekerjaan profesional?
        - Apakah pengguna telah beralih ke topik lain, meskipun mereka hanya mengajukan satu pertanyaan relevan pada topik sebelumnya?

        Jika ya, maka GAIA akan memulai sesi Quiz.
      `);

      setTimeout(() => {
        fetchHandleSpeakQuiz();
      }, 3000);
    }
  }, [saveChat.chat]);

  useEffect(() => {
    if (filterChatWithQuestion.length > 0) {
      setIsQuizStarted(true);
    }
  }, [filterChatWithQuestion.length]);

  useEffect(() => {
    if (!stream) {
      setIsQuizStarted(false);
    }
  }, [stream]);

  const [isShowInfo, setIsShowInfo] = useState(false);
  const [isShowModeInfo, setIsShowModeInfo] = useState(false);
  const [isShowEndButtonInfo, setIsShowEndButtonInfo] = useState(false);

  useEffect(() => {
    setIsShowModeInfo(false);
    setIsShowEndButtonInfo(false);
  }, [isScrolled]);

  const theme = useSystemStore((state) => state.theme);

  const { tenantData } = useAuthStore();

  return (
    <>
      {!stream && (
        <>
          {isShowInfo && !stream && (
            <div className="fixed bottom-140 right-20 z-[100]">
              <div
                className="relative max-w-[347px] text-black p-16 rounded-xl"
                style={{
                  backdropFilter: "blur(40px)",
                  boxShadow: "0px 4px 6px 0px #0000000D",
                  backgroundColor: theme == "light" ? "#000000CC" : "#FFFFFFCC",
                }}
              >
                <p
                  className="text-b2 font-light"
                  style={{
                    color: theme == "light" ? "#FFFFFF" : "#19093A",
                  }}
                >
                  Coach AI interaktif yang memberikan bimbingan, pelatihan
                  personal, dan feedback secara real-time
                </p>
                <div
                  className="absolute -bottom-[9.5px] right-28 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent"
                  style={{
                    borderTop:
                      theme == "light"
                        ? "10px solid #000000CC"
                        : "10px solid #FFFFFFCC",
                  }}
                ></div>
              </div>
            </div>
          )}

          <div className="fixed bottom-6 right-4 z-50">
            <div
              className="relative cursor-pointer"
              onMouseEnter={() => setIsShowInfo(true)}
              onMouseLeave={() => setIsShowInfo(false)}
              onClick={
                remainingDuration > 0 && currentUser
                  ? startSession
                  : remainingDuration > 0 && !currentUser
                  ? () => {
                      const currentPath = router.asPath;
                      router.replace({
                        pathname: "/login",
                        query: {
                          url: encodeURIComponent(currentPath),
                        },
                      });
                    }
                  : remainingDuration <= 0 && currentUser
                  ? (e) => {
                      e.stopPropagation();

                      trackEventGTM({
                        event: "gaia_quota_s",
                        property: {
                          user_id: currentUser?.id ?? "",
                          is_logged_in: currentUser ? true : false,
                          session_id: dataAvatar?.session_id,
                          screen_state: isScrolled ? "minimize" : "maximize",
                        },
                      });

                      trackEventGA({
                        event: "gaia_quota_s",
                        property: {
                          user_id: currentUser?.id ?? "",
                          is_logged_in: currentUser ? true : false,
                          session_id: dataAvatar?.session_id,
                          screen_state: isScrolled ? "minimize" : "maximize",
                        },
                      });

                      setShowLimitDesc(!showLimitDesc);
                    }
                  : () => {
                      const currentPath = router.asPath;
                      router.replace({
                        pathname: "/login",
                        query: {
                          url: encodeURIComponent(currentPath),
                        },
                      });
                    }
              }
            >
              <Image
                width={130}
                height={128}
                src="/images/gaia-box-widget.png"
                alt="gaia-box-widget"
              />

              <video
                className="absolute top-12 right-20 w-[98px] h-[72px] rounded-[19px] object-cover"
                src={`/images/${
                  (tenantData as any)?.setting?.heygen_avatar
                }.mp4`}
                loop
                playsInline
                autoPlay
              ></video>

              {isLoadingSession && (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="absolute rounded-[19px] z-50 top-12 right-20"
                  style={{
                    width: "98px",
                    height: "72px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  <div className="relative -bottom-28 -right-38">
                    <LoaderFadeInOut />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div
        ref={containerRef}
        className={`${
          isScrolled ? "fixed w-[10px] h-[10px] z-30" : "w-full h-full"
        } inset-0 transition-all duration-500 ease-in-out opacity-100`}
      >
        <div
          ref={videoContainerRef}
          className={`${
            isScrolled && !isSmaller
              ? "fixed bottom-20 right-20"
              : isScrolled && isSmaller
              ? "fixed bottom-20 right-20"
              : "relative w-full h-[calc(100vh-68px)]"
          } flex items-center justify-center`}
        >
          <video
            webkit-playsinline="true"
            playsInline
            onClick={(e) => {
              e.stopPropagation();

              if (!currentUser) {
                const currentPath = router.asPath;
                router.replace({
                  pathname: "/login",
                  query: {
                    url: encodeURIComponent(currentPath),
                  },
                });
              }
            }}
            className={`w-full aspect-[9/16] ${
              !isScrolled ? "h-full" : "h-full rounded-xl"
            } object-cover transition-all duration-500 ease-in-out ${
              !stream && "hidden"
            }`}
            style={{ boxShadow: stream && "4px 4px 12px 0px #0000004D" }}
            id="sm-video"
            ref={(ref) => {
              mediaStream.current = ref;
              if (ref && !isMediaReady) {
                setIsMediaReady(true);
              }
            }}
          />

          {stream && showAdditionalContent && (
            <>
              <motion.div
                className={`absolute top-0 ${
                  !isScrolled ? "left-0" : "left-0 rounded-tl-xl"
                } px-16 py-10 rounded-br-xl flex justify-center gap-8`}
                style={{
                  background:
                    "linear-gradient(270deg, rgba(85, 31, 193, 0.8) 0%, rgba(243, 122, 65, 0.8) 100%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  if (!currentUser) {
                    const currentPath = router.asPath;
                    router.replace({
                      pathname: "/login",
                      query: {
                        url: encodeURIComponent(currentPath),
                      },
                    });
                  }
                }}
              >
                <Image
                  src={"/images/star-gaia.svg"}
                  alt="gaia"
                  width={16}
                  height={16}
                />
                <p className="text-white_to_white text-b1 font-medium">GAIA</p>
                {!isScrolled && (
                  <p className="text-white_to_white text-b1 font-light">
                    | goKampus AI Advisor
                  </p>
                )}
              </motion.div>

              {!isScrolled && (
                <>
                  {stream && isShowModeInfo && (
                    <div className="fixed top-140 right-58 z-[100]">
                      <div className="relative max-w-[347px] text-black p-8 rounded-xl bg-[rgba(0,0,0,0.72)]">
                        <p
                          className="text-b2 font-light"
                          style={{
                            color: theme == "light" ? "#FFFFFF" : "#19093A",
                          }}
                        >
                          Assistant Mode
                        </p>
                        <div
                          className="absolute -top-[9.5px] right-44 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent"
                          style={{
                            borderBottom: "10px solid #000000CC",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}

                  <motion.div
                    className={`absolute top-10 right-[90px] p-10 rounded-full cursor-pointer hover:bg-[rgba(0,0,0,0.08)]`}
                    onMouseEnter={() => setIsShowModeInfo(true)}
                    onMouseLeave={() => setIsShowModeInfo(false)}
                    onClick={(e) => {
                      e.stopPropagation();

                      if (!currentUser) {
                        const currentPath = router.asPath;
                        router.replace({
                          pathname: "/login",
                          query: {
                            url: encodeURIComponent(currentPath),
                          },
                        });
                      } else {
                        handleMinimize(e);
                      }
                    }}
                  >
                    <Image
                      src={"/images/assistant.svg"}
                      alt="assistant"
                      width={24}
                      height={24}
                    />
                  </motion.div>

                  {stream && isShowEndButtonInfo && (
                    <div className="fixed top-140 right-28 z-[100]">
                      <div className="relative max-w-[347px] text-black p-8 rounded-xl bg-[rgba(0,0,0,0.72)]">
                        <p
                          className="text-b2 font-light"
                          style={{
                            color: theme == "light" ? "#FFFFFF" : "#19093A",
                          }}
                        >
                          Collapse
                        </p>
                        <div
                          className="absolute -top-[9.5px] right-24 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent"
                          style={{
                            borderBottom: "10px solid #000000CC",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}

                  <motion.div
                    className={`absolute top-10 right-40 p-10 rounded-full cursor-pointer hover:bg-[rgba(0,0,0,0.08)]`}
                    onClick={endSession}
                    onMouseEnter={() => setIsShowEndButtonInfo(true)}
                    onMouseLeave={() => setIsShowEndButtonInfo(false)}
                  >
                    <Image
                      src="/images/end.svg"
                      alt="end"
                      width={24}
                      height={24}
                    />
                  </motion.div>
                </>
              )}

              {!isSmaller && (
                <motion.div
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!currentUser) {
                      const currentPath = router.asPath;
                      router.replace({
                        pathname: "/login",
                        query: {
                          url: encodeURIComponent(currentPath),
                        },
                      });
                    }
                  }}
                  className={`absolute ${
                    isScrolled && "rounded-b-xl"
                  } bottom-0 h-64 w-full`}
                  style={{
                    backdropFilter: isScrolled ? "blur(100px)" : "blur(24px)",
                    backgroundColor: isScrolled ? "#FFFFFF14" : "#00000024",
                  }}
                ></motion.div>
              )}

              {!isSmaller && (
                <motion.div
                  className={`absolute ${
                    isScrolled
                      ? "left-10 bottom-80 opacity-50 min-w-[45%] gap-8 items-end"
                      : "left-40 bottom-16 flex-col gap-4"
                  } pt-10 rounded-full flex`}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!currentUser) {
                      const currentPath = router.asPath;
                      router.replace({
                        pathname: "/login",
                        query: {
                          url: encodeURIComponent(currentPath),
                        },
                      });
                    }
                  }}
                >
                  <p className="text-white_to_white text-c1 font-light">
                    Powered by
                  </p>
                  <Image
                    src={"/images/logo-nxtx-dark.png"}
                    alt="nxtx"
                    width={66}
                    height={11}
                    className={isScrolled && "grow"}
                  />
                </motion.div>
              )}

              {!stream && !isScrolled && (
                <motion.div className="absolute bottom-140 flex flex-col gap-16 justify-center items-center text-center">
                  <h2 className="text-white_to_white text-h2 max-w-[600px] font-medium">
                    Upskill Kompetensi Profesional Anda dengan AI Interaktif
                  </h2>
                  <p className="text-white_to_white text-b1 max-w-[550px] font-light">
                    Bertanya, berdiskusi dan dapatkan feedback secara langsung
                    tanpa tunggu
                  </p>
                </motion.div>
              )}

              {isScrolled && (
                <motion.div
                  className={`absolute bottom-12 right-10 p-10 rounded-full cursor-pointer z-50`}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.44)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    setShowAllSidebar(!showAllSidebar);
                  }}
                >
                  <Image
                    src={"/images/hamburger-point.svg"}
                    alt="hamburger-point"
                    width={24}
                    height={24}
                  />
                </motion.div>
              )}

              {!isScrolled ? (
                <>
                  {/* {isQuizStarted && (
                    <motion.div
                      className={`absolute right-[432px] bottom-10 px-16 py-10 rounded-full flex justify-center gap-8 cursor-pointer`}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                      }}
                      onClick={
                        currentUser
                          ? toggleQuizSidebar
                          : () => {
                              const currentPath = router.asPath;
                              router.replace({
                                pathname: "/login",
                                query: {
                                  url: encodeURIComponent(currentPath),
                                },
                              });
                            }
                      }
                    >
                      <Image
                        src={"/images/quiz.svg"}
                        alt="quiz"
                        width={20}
                        height={20}
                      />
                      <p className="text-white_to_white text-b1 font-light">
                        Quiz
                      </p>
                    </motion.div>
                  )} */}

                  <motion.div
                    className={`absolute right-170 bottom-10 px-16 py-10 rounded-full flex justify-center gap-8 cursor-pointer`}
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.44)",
                    }}
                    onClick={
                      currentUser
                        ? toggleSidebar
                        : () => {
                            const currentPath = router.asPath;
                            router.replace({
                              pathname: "/login",
                              query: {
                                url: encodeURIComponent(currentPath),
                              },
                            });
                          }
                    }
                  >
                    <Image
                      src={"/images/transcript.svg"}
                      alt="transcript"
                      width={20}
                      height={20}
                    />
                    <p className="text-white_to_white text-b1 font-light">
                      Transcript
                    </p>
                  </motion.div>

                  <motion.div
                    className={`absolute right-40 bottom-10 px-16 py-10 rounded-full flex justify-center gap-8 cursor-pointer`}
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.44)",
                    }}
                    onClick={
                      currentUser
                        ? toggleSummarySidebar
                        : () => {
                            const currentPath = router.asPath;
                            router.replace({
                              pathname: "/login",
                              query: {
                                url: encodeURIComponent(currentPath),
                              },
                            });
                          }
                    }
                  >
                    <Image
                      src={"/images/summary.svg"}
                      alt="summary"
                      width={20}
                      height={20}
                    />
                    <p className="text-white_to_white text-b1 font-light">
                      Summary
                    </p>
                  </motion.div>

                  <SidebarDesktop
                    isQuizStarted={isQuizStarted}
                    setIsQuizStarted={setIsQuizStarted}
                    saveChat={saveChat}
                    filterChatWithQuestion={filterChatWithQuestion}
                    textValue={textValue}
                    setTextValue={setTextValue}
                    handleSpeakText={handleSpeakText}
                    showSidebar={showSidebar}
                    toggleSidebar={toggleSidebar}
                    chat={chat}
                    lastDisplayedDate={lastDisplayedDate}
                    currentUser={currentUser}
                    handleCopyText={handleCopyText}
                    copySuccess={copySuccess}
                    chatEndRef={chatEndRef}
                    showSummarySidebar={showSummarySidebar}
                    toggleSummarySidebar={toggleSummarySidebar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    stream={stream}
                    historySummary={historySummary}
                    summaryRecap={summaryRecap}
                    summaryEndRef={summaryEndRef}
                    showQuizSidebar={showQuizSidebar}
                    toggleQuizSidebar={toggleQuizSidebar}
                    customClassTranscript="absolute rounded-xl w-[35%] sidebar-dynamic-height mb-20 bottom-70 right-40 px-16 pb-20 pt-50 transition-all duration-700 ease-in-out"
                    customClassSummary="absolute rounded-xl w-[35%] sidebar-dynamic-height mb-20 bottom-70 right-40 px-16 pb-20 pt-50 transition-all duration-700 ease-in-out"
                  />
                </>
              ) : (
                <>
                  {showAllSidebar && (
                    <motion.div
                      className={`fixed bottom-[90px] text-white_to_white right-30 p-10 rounded-16 cursor-pointer z-[200]`}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.44)",
                        backdropFilter: "blur(24px)",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();

                        setShowAllSidebar(!showAllSidebar);
                      }}
                    >
                      {isQuizStarted && (
                        <motion.div
                          className="p-4 text-b2 font-light"
                          onClick={
                            currentUser
                              ? toggleQuizSidebar
                              : () => {
                                  const currentPath = router.asPath;
                                  router.replace({
                                    pathname: "/login",
                                    query: {
                                      url: encodeURIComponent(currentPath),
                                    },
                                  });
                                }
                          }
                        >
                          Quiz
                        </motion.div>
                      )}

                      <motion.div
                        className="p-4 text-b2 font-light"
                        onClick={
                          currentUser
                            ? toggleSidebar
                            : () => {
                                const currentPath = router.asPath;
                                router.replace({
                                  pathname: "/login",
                                  query: {
                                    url: encodeURIComponent(currentPath),
                                  },
                                });
                              }
                        }
                      >
                        Transcript
                      </motion.div>
                      <motion.div
                        className="p-4 text-b2 font-light"
                        onClick={
                          currentUser
                            ? toggleSummarySidebar
                            : () => {
                                const currentPath = router.asPath;
                                router.replace({
                                  pathname: "/login",
                                  query: {
                                    url: encodeURIComponent(currentPath),
                                  },
                                });
                              }
                        }
                      >
                        Summary
                      </motion.div>
                    </motion.div>
                  )}

                  <BottomNavMobile
                    isQuizStarted={isQuizStarted}
                    setIsQuizStarted={setIsQuizStarted}
                    saveChat={saveChat}
                    filterChatWithQuestion={filterChatWithQuestion}
                    textValue={textValue}
                    setTextValue={setTextValue}
                    handleSpeakText={handleSpeakText}
                    showSidebar={showSidebar}
                    toggleSidebar={toggleSidebar}
                    chat={chat}
                    lastDisplayedDate={lastDisplayedDate}
                    currentUser={currentUser}
                    handleCopyText={handleCopyText}
                    copySuccess={copySuccess}
                    chatEndRef={chatEndRef}
                    showSummarySidebar={showSummarySidebar}
                    toggleSummarySidebar={toggleSummarySidebar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    stream={stream}
                    historySummary={historySummary}
                    summaryRecap={summaryRecap}
                    summaryEndRef={summaryEndRef}
                    showQuizSidebar={showQuizSidebar}
                    toggleQuizSidebar={toggleQuizSidebar}
                    customClassQuiz="absolute rounded-tl-xl rounded-tr-xl w-full h-auto bottom-0 px-16 pb-72 pt-58 transition-all duration-700 ease-in-out"
                    customClassTranscript="absolute rounded-tl-xl rounded-tr-xl w-full h-[50%] bottom-0 px-16 pb-72 pt-48 transition-all duration-700 ease-in-out"
                    customClassSummary="absolute rounded-tl-xl rounded-tr-xl w-full h-[50%] bottom-0 px-16 pb-72 pt-48 transition-all duration-700 ease-in-out"
                  />

                  {stream && isShowModeInfo && (
                    <div className="absolute top-70 right-[34px] z-[100]">
                      <div className="relative max-w-[347px] text-black p-8 rounded-xl bg-[rgba(0,0,0,0.72)]">
                        <p
                          className="text-b2 font-light"
                          style={{
                            color: theme == "light" ? "#FFFFFF" : "#19093A",
                          }}
                        >
                          Focus Mode
                        </p>
                        <div
                          className="absolute -top-[9.5px] right-38 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent"
                          style={{
                            borderBottom: "10px solid #000000CC",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}

                  <motion.div
                    className={`absolute ${
                      isScrolled && !isSmaller
                        ? "top-4"
                        : isScrolled && isSmaller
                        ? "bottom-8"
                        : "bottom-10"
                    } right-60 p-10 rounded-full cursor-pointer hover:bg-[rgba(0,0,0,0.08)]`}
                    onClick={handleFullScreen}
                    onMouseEnter={() => setIsShowModeInfo(true)}
                    onMouseLeave={() => setIsShowModeInfo(false)}
                  >
                    <Image
                      src={"/images/focus.svg"}
                      alt="focus"
                      width={24}
                      height={24}
                    />
                  </motion.div>

                  {stream && isShowEndButtonInfo && (
                    <div className="absolute top-70 right-4 z-[100]">
                      <div className="relative max-w-[347px] text-black p-8 rounded-xl bg-[rgba(0,0,0,0.72)]">
                        <p
                          className="text-b2 font-light"
                          style={{
                            color: theme == "light" ? "#FFFFFF" : "#19093A",
                          }}
                        >
                          Collapse
                        </p>
                        <div
                          className="absolute -top-[9.5px] right-24 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent"
                          style={{
                            borderBottom: "10px solid #000000CC",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}

                  <motion.div
                    className={`absolute ${
                      isScrolled && !isSmaller
                        ? "top-4"
                        : isScrolled && isSmaller
                        ? "bottom-8"
                        : "bottom-10"
                    } right-16 p-10 rounded-full cursor-pointer hover:bg-[rgba(0,0,0,0.08)]`}
                    onClick={endSession}
                    onMouseEnter={() => setIsShowEndButtonInfo(true)}
                    onMouseLeave={() => setIsShowEndButtonInfo(false)}
                  >
                    <Image
                      src="/images/end.svg"
                      alt="end"
                      width={24}
                      height={24}
                    />
                  </motion.div>
                </>
              )}

              {stream ? (
                <motion.div
                  className={`absolute ${
                    isScrolled ? "bottom-12 left-8" : "bottom-10"
                  } flex items-center gap-4 transition-all z-30`}
                >
                  <motion.div
                    className={`bg-white_to_white ${
                      isScrolled ? "w-[14vw]" : "w-[22vw] gap-2"
                    } rounded-full flex items-center transition-all duration-500 z-30`}
                  >
                    {chatMode === "text_mode" ? (
                      <div className="relative w-full flex justify-between">
                        <input
                          autoFocus
                          type="text"
                          placeholder="Ketik di sini"
                          value={textValue}
                          onChange={(e) => setTextValue(e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleInterrupt();
                              handleSubmit();
                            }
                          }}
                          disabled={!stream}
                          className={clsx(
                            "h-[35px] w-full pl-20 pr-40 text-b2 text-[#19093A] font-light rounded-full ring-2 ring-purple50_to_purple50 focus:outline-none",
                            isLoadingRepeat && "pr-10"
                          )}
                        />

                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInterrupt();
                            handleSubmit();
                          }}
                          className="absolute top-1/2 -translate-y-1/2 right-10 cursor-pointer bg-white_to_white"
                        >
                          <Image
                            src={
                              textValue.length > 0
                                ? "/images/send.svg"
                                : "/images/keyboard.svg"
                            }
                            alt={textValue.length > 0 ? "send" : "keyboard"}
                            width={24}
                            height={24}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {remainingDuration <= 120 && remainingDuration >= 0 && (
                          <motion.div
                            className="border-2 border-white_to_white rounded-full cursor-pointer py-12 px-12 flex justify-between items-center gap-8 text-white_to_white text-b2 font-medium"
                            style={{
                              backgroundColor: "#D0375CCC",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();

                              setShowLimitDesc(!showLimitDesc);
                            }}
                          >
                            {formatTime(remainingDuration)}

                            <Image
                              className="min-h-16 min-w-16"
                              src={"/images/countdown-info.svg"}
                              alt="countdown"
                              width={16}
                              height={16}
                            />
                          </motion.div>
                        )}

                        <AudioVisualizer
                          volumes={volumes}
                          customClass={`w-full ${
                            !(
                              remainingDuration <= 120 && remainingDuration >= 0
                            ) && "translate-x-[5%]"
                          } flex justify-center items-center gap-8 ${
                            isScrolled ? "" : "px-16"
                          } py-10 rounded-full bg-transparent`}
                        />

                        <button
                          // disabled={disabledMic}
                          id="mic-button"
                          onClick={
                            isVoiceChatActive
                              ? handleCloseVoiceChat
                              : handleStartVoiceChat
                          }
                          className={`opacity-100 hover:cursor-pointer p-10 transition-all duration-500 ease-in-out`}
                        >
                          <Image
                            src={`${
                              isVoiceChatActive
                                ? "/images/Microphone-On.svg"
                                : "/images/Microphone-Off-Purple.svg"
                            }`}
                            alt="mic"
                            width={24}
                            height={24}
                            className="min-w-24 min-h-24"
                          />
                        </button>

                        {micIsLoading && (
                          <motion.div
                            onClick={(e) => e.stopPropagation()}
                            className={`absolute ${
                              isScrolled ? "right-0" : "right-48"
                            } rounded-full z-50`}
                            style={{
                              width: "44px",
                              height: "44px",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            <motion.div className="relative -bottom-10 -right-18">
                              <LoaderFadeInOut />
                            </motion.div>
                          </motion.div>
                        )}
                      </>
                    )}
                  </motion.div>

                  <div className="relative">
                    <motion.div
                      className={`${
                        chatMode === "text_mode"
                          ? "bg-purple50_to_purple50"
                          : "bg-white_to_white"
                      } rounded-full flex items-center gap-2 transition-all duration-500 z-30`}
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (chatMode === "text_mode") {
                            trackEventGTM({
                              event: "gaia_mic_a",
                              property: {
                                user_id: currentUser?.id ?? "",
                                is_logged_in: currentUser ? true : false,
                                session_id: dataAvatar?.session_id,
                              },
                            });

                            trackEventGA({
                              event: "gaia_mic_a",
                              property: {
                                user_id: currentUser?.id ?? "",
                                is_logged_in: currentUser ? true : false,
                                session_id: dataAvatar?.session_id,
                              },
                            });

                            handleChangeChatMode("voice_mode");
                          } else {
                            trackEventGTM({
                              event: "gaia_text_a",
                              property: {
                                user_id: currentUser?.id ?? "",
                                is_logged_in: currentUser ? true : false,
                                session_id: dataAvatar?.session_id,
                              },
                            });

                            trackEventGA({
                              event: "gaia_text_a",
                              property: {
                                user_id: currentUser?.id ?? "",
                                is_logged_in: currentUser ? true : false,
                                session_id: dataAvatar?.session_id,
                              },
                            });

                            handleChangeChatMode("text_mode");
                          }
                        }}
                        id="mode-button"
                        className={`opacity-100 hover:cursor-pointer p-10 transition-all duration-500 ease-in-out`}
                      >
                        <Image
                          src={`${
                            chatMode === "text_mode"
                              ? "/images/Microphone-On-White.svg"
                              : "/images/keyboard.svg"
                          }`}
                          alt="mode"
                          width={24}
                          height={24}
                        />
                      </button>
                    </motion.div>

                    {modeIsLoading && (
                      <motion.div
                        onClick={(e) => e.stopPropagation()}
                        className={`absolute bottom-0 right-0 rounded-full z-50`}
                        style={{
                          width: "44px",
                          height: "44px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <motion.div className="relative -bottom-10 -right-18">
                          <LoaderFadeInOut />
                        </motion.div>
                      </motion.div>
                    )}
                  </div>
                </motion.div>
              ) : (
                <>
                  {!isSmaller && (
                    <>
                      {remainingDuration > 120 && (
                        <motion.div
                          className={`absolute ${
                            isScrolled ? "bottom-8" : "bottom-10"
                          } flex items-center gap-2 transition-all duration-500`}
                        >
                          <button
                            id="start-button"
                            // disabled={remainingDuration <= 0}
                            onClick={
                              currentUser
                                ? startSession
                                : () => {
                                    const currentPath = router.asPath;
                                    router.replace({
                                      pathname: "/login",
                                      query: {
                                        url: encodeURIComponent(currentPath),
                                      },
                                    });
                                  }
                            }
                            className={`opacity-100 rounded-full py-10 px-16 flex gap-2 items-center transition-all duration-500 ease-in-out bg-white_to_white text-purple50_to_purple50 text-b2 font-medium ${
                              remainingDuration <= 0
                                ? "cursor-not-allowed opacity-50"
                                : "cursor-pointer"
                            }`}
                          >
                            <Image
                              src="/images/play.svg"
                              alt="play"
                              width={24}
                              height={24}
                            />
                            Start Session
                          </button>
                        </motion.div>
                      )}

                      {remainingDuration <= 120 && remainingDuration >= 0 && (
                        <>
                          {currentUser ? (
                            <motion.div
                              className={`absolute ${
                                isScrolled
                                  ? "bottom-8 left-[20%]"
                                  : !isScrolled &&
                                    remainingDuration <= 120 &&
                                    remainingDuration >= 0
                                  ? "bottom-10 left-[45%]"
                                  : "bottom-10"
                              } flex items-center gap-4 transition-all z-30`}
                            >
                              <motion.div className="flex items-center gap-2 transition-all duration-500 z-30">
                                <button
                                  id="start-button"
                                  // disabled={remainingDuration <= 0}
                                  onClick={
                                    remainingDuration > 0 && currentUser
                                      ? startSession
                                      : remainingDuration > 0 && !currentUser
                                      ? () => {
                                          const currentPath = router.asPath;
                                          router.replace({
                                            pathname: "/login",
                                            query: {
                                              url: encodeURIComponent(
                                                currentPath
                                              ),
                                            },
                                          });
                                        }
                                      : remainingDuration <= 0 && currentUser
                                      ? (e) => {
                                          e.stopPropagation();

                                          trackEventGTM({
                                            event: "gaia_quota_s",
                                            property: {
                                              user_id: currentUser?.id ?? "",
                                              is_logged_in: currentUser
                                                ? true
                                                : false,
                                              session_id:
                                                dataAvatar?.session_id,
                                              screen_state: isScrolled
                                                ? "minimize"
                                                : "maximize",
                                            },
                                          });

                                          trackEventGA({
                                            event: "gaia_quota_s",
                                            property: {
                                              user_id: currentUser?.id ?? "",
                                              is_logged_in: currentUser
                                                ? true
                                                : false,
                                              session_id:
                                                dataAvatar?.session_id,
                                              screen_state: isScrolled
                                                ? "minimize"
                                                : "maximize",
                                            },
                                          });

                                          setShowLimitDesc(!showLimitDesc);
                                        }
                                      : () => {
                                          const currentPath = router.asPath;
                                          router.replace({
                                            pathname: "/login",
                                            query: {
                                              url: encodeURIComponent(
                                                currentPath
                                              ),
                                            },
                                          });
                                        }
                                  }
                                  className={`opacity-100 rounded-full p-10 flex gap-2 items-center transition-all duration-500 ease-in-out bg-white_to_white text-purple50_to_purple50 text-b2 font-medium cursor-pointer`}
                                >
                                  <Image
                                    src="/images/play.svg"
                                    alt="play"
                                    width={24}
                                    height={24}
                                  />
                                </button>
                              </motion.div>

                              {!isSmaller && (
                                <motion.div className={`absolute gap-8`}>
                                  <motion.div
                                    className="rounded-full cursor-pointer pl-50 py-12 pr-30 flex items-center gap-8 text-white_to_white text-b2 font-medium"
                                    style={{
                                      backgroundColor: "#D0375CCC",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      setShowLimitDesc(!showLimitDesc);
                                    }}
                                  >
                                    {formatTime(remainingDuration)}

                                    <Image
                                      className="h-16 w-16"
                                      src={"/images/countdown-info.svg"}
                                      alt="countdown"
                                      width={16}
                                      height={16}
                                    />
                                  </motion.div>
                                </motion.div>
                              )}
                            </motion.div>
                          ) : (
                            <motion.div
                              className={`absolute ${
                                isScrolled ? "bottom-8" : "bottom-10"
                              } flex items-center gap-2 transition-all duration-500`}
                            >
                              <button
                                id="start-button"
                                // disabled={remainingDuration <= 0}
                                onClick={
                                  currentUser
                                    ? startSession
                                    : () => {
                                        const currentPath = router.asPath;
                                        router.replace({
                                          pathname: "/login",
                                          query: {
                                            url: encodeURIComponent(
                                              currentPath
                                            ),
                                          },
                                        });
                                      }
                                }
                                className={`opacity-100 rounded-full py-10 px-16 flex gap-2 items-center transition-all duration-500 ease-in-out bg-white_to_white text-purple50_to_purple50 text-b2 font-medium cursor-pointer`}
                              >
                                <Image
                                  src="/images/play.svg"
                                  alt="play"
                                  width={24}
                                  height={24}
                                />
                                Start Session
                              </button>
                            </motion.div>
                          )}
                        </>
                      )}

                      {isLoadingSession && (
                        <>
                          {remainingDuration <= 120 &&
                          remainingDuration >= 0 ? (
                            <motion.div
                              onClick={(e) => e.stopPropagation()}
                              className={`rounded-full absolute ${
                                isScrolled
                                  ? "bottom-8 -translate-x-4"
                                  : "bottom-10 -translate-x-12"
                              } z-50`}
                              style={{
                                height: "45px",
                                width: "124px",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <motion.div className="relative -bottom-12 -right-70">
                                <LoaderFadeInOut />
                              </motion.div>
                            </motion.div>
                          ) : (
                            <motion.div
                              onClick={(e) => e.stopPropagation()}
                              className={`rounded-full absolute ${
                                isScrolled ? "bottom-8" : "bottom-10"
                              } z-50`}
                              style={{
                                height: "45px",
                                width: "144px",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <motion.div className="relative -bottom-12 -right-70">
                                <LoaderFadeInOut />
                              </motion.div>
                            </motion.div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {showLimitDesc &&
                Limit(
                  `absolute bottom-88 ${isScrolled && "mx-4"} max-w-[40vw]`,
                  "Kuota GAIA Hampir Habis!",
                  <p className="font-light text-b2 text-black_to_black">
                    Agar diskusi interaktif dengan AI dapat tetap berjalan tanpa
                    hambatan, ajukan peningkatan limit sekarang!
                  </p>,
                  setShowLimitDesc,
                  isIncreaseLimit,
                  isRequestSent,
                  setIsRequestSent,
                  limitChecked,
                  setLimitChecked,
                  showToast,
                  isScrolled,
                  handleRequestQuota,
                  currentUser,
                  dataAvatar,
                  maxDuration
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
