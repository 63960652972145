import { format } from "date-fns";

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const today = new Date();
  const diffInDays = Math.floor(
    (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
  );

  if (diffInDays === 0) {
    return "Today";
  } else if (diffInDays <= 7) {
    return format(date, "EEEE"); // Nama hari, misal: "Monday"
  } else {
    return format(date, "dd/MM/yyyy"); // Format 00/00/0000
  }
};
